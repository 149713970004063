export const EMAIL_TYPES = [
    'subcontractorRequest',
    'warrantyFinished',
    'warrantyNotice',
    'warrantyReject',
    'warrantySendStatus',
    'rejectLetter',
    'subcontractorRejectObjection'
] as const;

export type EmailType = typeof EMAIL_TYPES[number];

export type EmailTemplate = {
    subject: string;
    body: string;
};

export type EmailCustomConfiguration = {
    [Key in EmailType]: EmailTemplate;
};

export type UpdateEmailConfig =
    (
        emailType: EmailType,
        field: keyof EmailTemplate,
        value: string
    ) => void