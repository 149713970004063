import {useAppSelector} from "../store/hooks";
import {CommonStoreStateKeys} from "../store/slices/commonStoreSlice";
import React, {useEffect} from "react";
import PageContainer from "../components/PageContainer";
import {StyledButton, StyledIconButton} from "@bau/material";
import {useLocation, useParams} from "react-router-dom";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/outline";
import {Button, FormControl, NativeSelect, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import {isUserPrincipal, isUserSubcontractor, translate} from "../utils/utils";

const DefectSaveSelect = styled(NativeSelect)(({ theme }) => ({
    '&:after': {
        borderBottom: 0,
    },
    '&:before': {
        borderBottom: 0,
    },
    '&:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: 0,
    },
    '& .MuiSvgIcon-root': {
        color: 'white',
        position: 'absolute',
        left: '.55rem'
    },
    '& .MuiNativeSelect-select.MuiInputBase-input.MuiInput-input': {
        color: theme.palette.secondary.main,
        borderRadius: '25px',
        backgroundColor: theme.palette.secondary.main,
        padding: '.55rem',
        width: '1.5rem',
        '&:focus': {
            borderRadius: '25px',
            backgroundColor: theme.palette.secondary.main,
        },
    }
}))


const Defect = () => {

    const currentInspection = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.currentInspection]);
    const user = useAppSelector(state => state.commonReducer[CommonStoreStateKeys.user]);
    const {defectPrefixId} = useParams();

    const {state} = useLocation();

    useEffect(()=>{
        let inspectionId;
        if(!!currentInspection) {
            inspectionId = currentInspection.id;
        }
        if(!state || !state.noUseEffect) {
            // @ts-ignore
            window.defectUseEffect(defectPrefixId, inspectionId, state);
        }
        // @ts-ignore
        !!state && !!state.callback && !!window.showPageCallback && window.showPageCallback();
        // @ts-ignore
        window.showPageCallback = null;
    },[defectPrefixId])



    let right = [
        <div id="activeSaveButtonList" style={{visibility: 'hidden'}} key={"activeSaveButtonList"}>
            <StyledButton data-shortcut="defectSave" className="save">
                {translate("defect.save")}
            </StyledButton>
            <StyledButton data-shortcut="defectSaveAndNew" className="saveandnew">
                {translate("defect.saveandnew")}
            </StyledButton>
            <StyledButton data-shortcut="defectSaveAndDuplicate"
                          className="saveandduplicate">
                {translate("defect.saveandduplicate")}
            </StyledButton>
            <StyledButton className="saveandfix">
                {translate("defect.saveandfix")}
            </StyledButton>
            <StyledButton className="saveandchecklist">
                {translate("defect.saveandchecklist")}
            </StyledButton>
            <StyledButton data-shortcut="defectSaveAndNext" className="saveandNext">
                {translate("defect.saveandNext")}
            </StyledButton>
        </div>,
        <StyledIconButton key={"actionstrigger"} className="options-menu-button actionstrigger">
            <EllipsisHorizontalIcon/>
        </StyledIconButton>,
        <FormControl key={"saveMenuDropdown"} id="saveMenuDropdown" variant={"standard"}>
            <DefectSaveSelect defaultValue={"saveandnew"}>
                <option className="save" value={"save"}>{translate("defect.save")}</option>
                <option className="saveandnew" value={"saveandnew"}>{translate("defect.saveandnew")}</option>
                <option className="saveandduplicate" value={"saveandduplicate"}>{translate("defect.saveandduplicate")}</option>
                <option className="saveandNext" value={"saveandNext"}>{translate("defect.saveandNext")}</option>
                <option id="saveMenuSplitLine" disabled>──────────</option>
                <option className="duplicate" value={"duplicate"}>{translate("defect.duplicate")}</option>
                <option className="repeatDefect" value={"repeatDefect"}>{translate("defect.repeat")}</option>
                <option className="createFollowup" value={"createFollowup"}>{translate("defect.menu.createfollowup")}</option>
                <option className="createSM" value={"createSM"}>{translate("defect.menu.createsm")}</option>
            </DefectSaveSelect>
        </FormControl>
    ]


    const middle = [
        <IconButton style={{visibility: 'hidden'}} key="defect-favourite-btn" className="defect-favourite-btn">
            {<StarBorderRoundedIcon className="defect-favourite-no"/>}
            {<StarRateRoundedIcon className="defect-favourite-yes"/>}
        </IconButton>,
        <Typography key="defect-title" className="defect-title" variant={"h6"} sx={{display: {xs: 'none', sm: 'block'}}}/>,
        <Button key="defect-type-btn" className="defect-type-btn" variant="contained" sx={{display: {xs: 'block', sm: 'block', md:'none'}, maxWidth: {xs: '40px', sm: 'unset'}}} disableElevation/>,
        <Typography key="defect-type-title" className="defect-type-title" variant={"h6"} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}/>
    ]

    const left = [
        <StyledButton className="previousPage" sx={{display: {xs: 'none', sm: 'inline-flex'}}}>{translate("defect.backtolist")}</StyledButton>,
        <StyledButton className="previousPage" sx={{display: {xs: 'inline-flex', sm: 'none'}, minWidth: 'unset'}}>{<ArrowBackIosNewIcon/>}</StyledButton>
    ]

    if(isUserSubcontractor(user) || isUserPrincipal(user)) {
        middle.splice(0,1);
    }

    let additionalFooter = [
        <StyledButton key="previous-defect" className="previous-defect previous" >{translate("defect.previous")}</StyledButton>,
        <StyledButton key="next-defect" className="next-defect next" >{translate("defect.next")}</StyledButton>
    ]
    return <PageContainer newPage={false}
                          left={left}
                          middle={middle}
                          additionalFooter={additionalFooter}
                          right={right}>

    </PageContainer>
}

export default Defect;