export const ROLE_MYACCOUNT_USER = "ROLE_MYACCOUNT_USER";
export const ROLE_OLD_SUBCONTRACTOR = "ROLE_OLD_SUBCONTRACTOR";
export const costType = {
    GLOBAL: 'GLOBAL',
    SPECIFIC: 'SPECIFIC',
    SEPARATE: 'SEPARATE'
}
export const localStorageItem = {
    OA_CUSTOMER: 'oa-customer',
    OA_PROJECT: 'oa-project',
}


export const serviceDataCommunicationSource = {
    FROM_QM: 'fromQm',
    FROM_FD2: 'fromFd2',
    FROM_OT2: 'fromOt2',
    FROM_OA: 'fromOa',
}

export const serviceDataCommunicationCommand = {
    CREATE_DEFECT: 'createDefect',
    SHOW_DEFECT: 'showDefect',
}

export const appVersion = {
    IOS: '',
    ANDROID: ''
}

export const additionalInfo = {
    DEFECT_DEADLINE:"DEFECT_DEADLINE",
    DEFECT_CREATOR:"DEFECT_CREATOR",
    ACTUALISATION_DATE:"ACTUALISATION_DATE",
    DEFECT_CREATION_DATE:"DEFECT_CREATION_DATE",
    DEFECT_SUBCONTRACTOR:"DEFECT_SUBCONTRACTOR",
    DEFECT_ESCALATIONLEVEL:"DEFECT_ESCALATIONLEVEL"
}

export enum page {
    NONE="NONE",
    SETTINGS="SETTINGS",
    PERSON_CSV="PERSON_CSV",
    BUYER_ROLES="BUYER_ROLES",
    BUYER_ACCESS="BUYER_ACCESS",
    WARRANTY_DATA_OVERVIEW="WARRANTY_DATA_OVERVIEW"
}

export interface pageScrollData {
    top:number,
    scrolledPage:page
}