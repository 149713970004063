define([
    '../lib/backbone-1.1.2'
], function (
    Backbone
) {
    let page = "<script id=\"contentAdditions\" type=\"text/html\">\n" +
        "\n" +
        "\t<div class=\"buttonContainer\" data-role=\"controlgroup\" data-type=\"horizontal\" data-mini=\"true\">\n" +
        "\t\t<button class=\"reloadProtocolsBtn\" style=\"float: right; max-width:48%\"><%= t('inspections.protocols.reload') %></button>\n" +
        "\t\t<button class=\"finishMode\" style=\"max-width:48%;\"><%= t('inspection.buttonfinish') %></button>\n" +
        "\t\t<button class=\"extendedProtocol\" style=\"border-right: 1px solid #aaa\">Erweiterung christ-christ&#8230;</button>\n" +
        "\t\t<button class=\"requestBundle\" style=\"float: right; max-width:48%\"><%= t('inspection.tobundlepage') %></button>\n" +
        "\t</div>\n" +
        "\n" +
        "\t<div id=\"finishInspectionContainer\" data-role=\"controlgroup\">\n        <button class='ui-btn-active ui-btn ui-corner-all' id=\'inspection-other-settings-header\' style=\"margin: 5em 0 1ex\"><%= t(\'inspection.othersettings.show\') %></button>\n        <div id=\'inspection-other-settings\' style=\"display: none;\">" +
        "\n" +
        "\t\t<h2 style=\"margin: 2em 0 1ex\"><%- t('requestbundle.autodeadline') %></h2>\n" +
        "\t\t<table style=\"margin-bottom: 1em\">\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td>\n" +
        "\t\t\t\t\t<fieldset data-role=\"controlgroup\" style=\"margin-top: 0\">\n" +
        "\t\t\t\t\t\t<input type=\"radio\" name=\"inspection-deadline\" id=\"inspection-deadline-disable\" value=\"disable\" checked=\"checked\"/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-deadline-disable\"><%- t('requestbundle.autodeadline.disable') %></label>\n" +
        "\t\t\t\t\t\t<input type=\"radio\" name=\"inspection-deadline\" id=\"inspection-deadline-enable\" value=\"enable\"/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-deadline-enable\"><%- t('requestbundle.autodeadline.enable') %></label>\n" +
        "\t\t\t\t\t</fieldset>\n" +
        "\t\t\t\t</td>\n" +
        "\t\t\t\t<td style=\"vertical-align: bottom\">\n" +
        "\t\t\t\t\t<input class=\"finishDeadline\" type=\"date\" data-wrapper-class=\"controlgroup-textinput ui-btn\"/>\n" +
        "\t\t\t\t</td>\n" +
        "\t\t\t</tr>\n" +
        "            <tr>\n" +
        "                <td>\n" +
        "                    <label style=\"clear: left\"><input type=\"checkbox\" class=\"includeAll\"> <%= t('inspection.finish.includeAll') %></label>\n" +
        "                </td>\n" +
        "            </tr>\n" +
        "\t\t</table>\n" +
        "\n" +
        "\t\t<table style=\"margin-bottom: 1em; margin-top: 2%;\" class=\"inspection-generate-externintern-container\">\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td>\n" +
        "\t\t\t\t\t<fieldset data-role=\"controlgroup\" style=\"margin-top: 0\">\n" +
        "\t\t\t\t\t\t<input id=\"inspection-generate-intern\" type=\"radio\" name=\"inspection-visibility\" value='0'/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-generate-intern\"><%- t('inspection.labelfinish.intern') %></label>\n" +
        "\t\t\t\t\t\t<input id=\"inspection-generate-extern\" type=\"radio\" name=\"inspection-visibility\" value='1'/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-generate-extern\"><%- t('inspection.labelfinish.extern') %></label>\n" +
        "\t\t\t\t\t</fieldset>\n" +
        "\t\t\t\t</td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t</table>\n" +
        "\n" +
        "\t\t<h2 style=\"margin: 2em 0 1ex\"><%- t('inspection.groupby.header') %></h2>" +
        "\t\t<table style=\"margin-bottom: 1em;\" class=\"inspection-generate-groupby-container\">\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td>\n" +
        "\t\t\t\t\t<fieldset data-role=\"controlgroup\" style=\"margin-top: 0\">\n" +
        "\t\t\t\t\t\t<input id=\"inspection-groupby-none\" type=\"radio\" name=\"inspection-groupby\" value='none' checked=\"checked\"/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-groupby-none\"><%- t('inspection.groupby.none') %></label>\n" +
        "\t\t\t\t\t\t<input id=\"inspection-groupby-location\" type=\"radio\" name=\"inspection-groupby\" value='location'/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-groupby-location\"><%- t('inspection.groupby.location') %></label>\n" +
        "\t\t\t\t\t\t<input id=\"inspection-groupby-subcontractor\" type=\"radio\" name=\"inspection-groupby\" value='subcontractor'/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-groupby-subcontractor\"><%- t('inspection.groupby.subcontractor') %></label>\n" +
        "\t\t\t\t\t\t<input id=\"inspection-groupby-status\" type=\"radio\" name=\"inspection-groupby\" value='status'/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-groupby-status\"><%- t('inspection.groupby.status') %></label>\n" +
        "\t\t\t\t\t\t<input id=\"inspection-groupby-deadline\" type=\"radio\" name=\"inspection-groupby\" value='deadline'/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-groupby-deadline\"><%- t('inspection.groupby.deadline') %></label>\n" +
        "\t\t\t\t\t\t<input id=\"inspection-groupby-smod\" type=\"radio\" name=\"inspection-groupby\" value='smod'/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-groupby-smod\"><%- t('inspection.groupby.smod') %></label>\n" +
        "\t\t\t\t\t\t<input id=\"inspection-groupby-pmod\" type=\"radio\" name=\"inspection-groupby\" value='pmod'/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-groupby-pmod\"><%- t('inspection.groupby.pmod') %></label>\n" +
        "\t\t\t\t\t\t<input id=\"inspection-groupby-topics\" type=\"radio\" name=\"inspection-groupby\" value='topics'/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-groupby-topics\"><%- t('inspection.topics') %></label>\n" +
        "\t\t\t\t\t</fieldset>\n" +
        "\t\t\t\t</td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t</table>\n" +
        "\n" +
        "\t\t<h2 style=\"margin: 2em 0 1ex\"><%- t('inspection.orderby.header') %></h2>\n" +
        "\t\t<table style=\"margin-bottom: 1em;\" class=\"inspection-generate-orderby-container\">\n" +
        "\t\t\t<tr>\n" +
        "\t\t\t\t<td>\n" +
        "\t\t\t\t\t<fieldset data-role=\"controlgroup\" style=\"margin-top: 0\">\n" +
        "\t\t\t\t\t\t<input id=\"inspection-orderBy-numberasc\" type=\"radio\" name=\"inspection-orderby\" value=\"numberasc\" checked=\"checked\"/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-orderBy-numberasc\"><%- t(\'inspection.orderby.numberasc\') %></label>\n" +
        "\t\t\t\t\t\t<input id=\"inspection-orderBy-numberdesc\" type=\"radio\" name=\"inspection-orderby\" value=\"numberdesc\"/>\n" +
        "\t\t\t\t\t\t<label for=\"inspection-orderBy-numberdesc\"><%- t(\'inspection.orderby.numberdesc\') %></label>\n" +
        "\t\t\t\t\t</fieldset>\n" +
        "\t\t\t\t</td>\n" +
        "\t\t\t</tr>\n" +
        "\t\t</table>\n\n        <h2 style=\"margin: 2em 0 1ex\"><%- t(\'inspection.filename.header\') %></h2>\n        <h4 style=\"margin: 2em 0 1ex\"><%- t(\'inspection.filename.info\') %></h4>\n        <input data-inputname=\"inspectionFileName\" id=\"inspectionFileName\" type=\"text\"\n               placeholder=\"<%= t(\'inspection.filename.placeholder\') %>\"/>\n        </div>" +
        "\n" +
        "\t\t<h2 class=\"customFieldsContainer\" style=\"margin: 2em 0 1ex\"><%- t('inspection.customfields.header') %></h2>\n" +
        "\t\t<div id=\"customFieldsList\" class=\"customFieldsContainer\"></div>\n" +
        "\t\t<div id=\"customSectionList\" class=\"customFieldsContainer\"></div>\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\t\t<h2 class=\"notesContainer\" style=\"margin: 2em 0 1ex\"><%- t('inspection.notes.header') %></h2>\n" +
        "\t\t<div class=\"notesContainer\">\n" +
        "\t\t\t<ul data-role=\"listview\" data-inset=\"true\" class=\"inspectionNotes ui-corner-all\"></ul>\n" +
        "\t\t\t<div class=\"ui-grid-a\" data-role=\"controlgroup\" data-type=\"horizontal\">\n" +
        "\t\t\t\t<div class=\"ui-block-a ui-corner-all\">\n" +
        "\t\t\t\t\t<input id=\"inspectionNote\" type=\"text\" data-wrapper-class=\"controlgroup-textinput ui-btn\" placeholder=\"<%= t('inspection.menu.freetext') %>\"/>\n" +
        "\t\t\t\t</div>\n" +
        "\t\t\t\t<a id=\"addNote\" style=\"width: 10%\" class=\"ui-block-b ui-btn ui-corner-all ui-icon-plus ui-btn-icon-notext\">Hinzufügen</a>\n" +
        "\t\t\t</div>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\n" +
        "\t\t<div class=\"signatures\"></div>\n" +
        "\n" +
        "\n" +
        "\t\t<h4 style=\"margin: 1.5em 0 0\" class=\"attachedDocsHeader\"><%= t('inspection.attacheddocs.header') %></h4>\n" +
        "\t\t<div>\n" +
        "\t\t\t<ul data-role=\"listview\" data-inset=\"true\" class=\"attachedDocuments ui-corner-all\"></ul>\n" +
        "\t\t\t<button class=\"attachDocumentFromBaufoto ui-btn ui-btn-inline ui-btn-icon-left ui-icon-plus ui-corner-all\"><%= t('inspection.attachfddoc') %></button>\n" +
        "\t\t\t<button class=\"attachDocumentFromLocalStorage ui-btn ui-btn-inline ui-btn-icon-left ui-icon-plus ui-corner-all\"><%= t('inspection.attachlocaldoc') %></button>\n" +
        "\t\t\t\t<form class=\"attachDocumentToInspectionForm\" enctype=\"multipart/form-data\" method=\"POST\" action>\n" +
        "\t\t\t\t\t<input type=\"file\" name=\"file\" accept=\"application/pdf\" />\n" +
        "\t\t\t\t</form>\n" +
        "\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div data-role=\"popup\" class=\"uploadingInspectionAttachmentDialog\" data-dismissible=\"false\">\n" +
        "\t\t\t<div class=\"ui-loader ui-body-a ui-loader-verbose spinner\"><span class=\"ui-icon-loading\"></span><h1 class=\"spinner-percent\">0%</h1></div>\n" +
        "\t\t\t<button class=\"cancelInspectionAttachmentUpload ui-btn ui-btn-inline ui-btn-icon-left ui-corner-all\"><%= t('popup.default.cancel') %></button>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"ui-grid-a\">\n" +
        "\t\t\t<div class=\"ui-block-a\"><button class=\"finishInspection ui-btn ui-corner-all ui-btn-active\"><%= t('inspection.menu.finish') %></button></div>\n" +
        "\t\t\t<div class=\"ui-block-b\"><button class=\"sendInspection ui-btn ui-corner-all ui-btn-active\"><%= t('inspection.menu.send') %></button></div>\n" +
        "\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t\t<div class=\"document-frame-div\">\n" +
        "\t\t\t<img src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\"  class=\"document-frame-img\"/>\n" +
        "\t\t\t<iframe class=\"document-frame\"></iframe>\n" +
        "\t\t</div>\n" +
        "\n" +
        "\t</div>\n" +
        "</script>\n" +
        "\n" +
        "<script id=\"actionsmenuAdditions\" type=\"text/html\">\n" +
        "\t<li data-role=\"list-divider\" style=\"border-top-left-radius: .4em; border-top-right-radius: .4em\"><%= t('defects.menu.actions') %></li>\n" +
        "\t<li><a href=\"javascript:void(0)\" class=\"edit darkicon\"><%= t('inspection.menu.edit') %></a></li>\n" +
        "\t<!--<li><a href=\"javascript:void(0)\" class=\"requestBundle\"><%= t('inspection.tobundlepage') %></a></li> disabled 2016-06-15 -->\n" +
        "\t<li><label><input type=\"checkbox\" class=\"archive\"> <%= t('inspection.menu.archive') %></label></li>\n" +
        "\t<!--<li data-role=\"list-divider\"></li>-->\n" +
        "</script>";
    return page;
});