define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2',
	'../helper/interactiveSync', '../helper/watchdog', '../helper/iosapp', '../model/user', '../model/currentProject',
], function (
	$, _, Backbone,
	interactiveSync, watchdog, iosapp, user, currentProject
) {
	return new (Backbone.Model.extend({

		timeout: 60 * 1000, //timeout of 60s

		defaults: {
			syncing: false,
			pending: 0
		},

		initialize: function () {
			this.online       = _.bind(this.online, this);
			this.beforeunload = _.bind(this.beforeunload, this);

			var mode;
			if (iosapp.appavailable && !iosapp.androidappavailable && !iosapp.windowsappavailable) {
				mode = "app";
			} else if (iosapp.appavailable && iosapp.androidappavailable && !iosapp.windowsappavailable) {
				mode = "androidapp";
			} else if (iosapp.appavailable && !iosapp.androidappavailable && iosapp.windowsappavailable) {
				mode = "windowsapp";
			} else if ((typeof Storage !== 'undefined' && navigator.cookieEnabled)) {
				mode = "localstorage";
			} else {
				mode = "memory";
			}

			if (mode == "app") {
				this.queue = {
					addValue: function (value) {
						iosapp.sendEvent("offlinequeuesetproject", currentProject.id);
						iosapp.sendEvent("offlinequeueaddvalue", this._serializeValue(value));
					},
					//context is optional
					getProject: function (callback, context) {
						iosapp.call("offlinequeuegetproject", null, callback, typeof context !== 'undefined' ? context : this);
					},
					clear: function () {
						iosapp.sendEvent("offlinequeuedeleteall", null);
					},
					//context is optional
					getFirst: function (callback, context) {

						//TODO: remove when nobody uses app 1.2 any more.
						// window.oxcatchfaileddeserialize = _.bind(function () {
						// 	delete window.oxcatchfaileddeserialize;
						// 	this.removeFirst();
						// 	this.getSize(function (size) {
						// 		if (size > 0) {
						// 			this.getFirst(callback, context);
						// 		} else {
						// 			callback.call(context, null);
						// 		}
						// 	}, this);
						// }, this);

						iosapp.call("offlinequeuegetfirst", null, function (item) {
							try {
								callback.call(typeof context !== 'undefined' ? context : this, this._deserializeValue(item));
							} catch (e) {
								if (window.onerror) {
									window.onerror(e.message, 'offlineQueue:getFirst:iosapp.call', null, null, e);
								}
								// if (window.oxcatchfaileddeserialize) {
								// 	window.oxcatchfaileddeserialize();
								// }
								callback.call(typeof context !== 'undefined' ? context : this, null);
							}
						}, this);
					},
					setFirst: function (value) {
						//no implementation: not needed, original object cannot be modified
					},
					removeFirst: function () {
						iosapp.sendEvent("offlinequeuedeletefirst", null);
					},
					//context is optional
					getSize: function (callback, context) {
						iosapp.call("offlinequeuegetsize", null, callback, typeof context !== 'undefined' ? context : this);
					},
					_serializeValue: _.bind(this._serializeValue, this),
					_deserializeValue: _.bind(this._deserializeValue, this)
				};
			}
			else if (mode == "androidapp") {
				this.queue = {
					addValue: function (value) {
						androidapp.addValue(this._serializeValue(value), currentProject.id);
					},
					clear: function () {
						androidapp.clearOfflineQueue();
					},
					//context is optional
					getProject: function (callback, context) {
						callback.call(typeof context !== 'undefined' ? context : this, androidapp.getCurrentProjectID());
					},

					//context is optional
					getFirst: function (callback, context) {
						try {
							callback.call(typeof context !== 'undefined' ? context : this, this._deserializeValue(androidapp.getFirstFromOfflineQueue()));
						} catch (e) {
							if (window.onerror) {
								window.onerror(e.message, 'offlineQueue:getFirst:androidapp.call', null, null, e);
							}
							// if (window.oxcatchfaileddeserialize) {
							// 	window.oxcatchfaileddeserialize();
							// }
							callback.call(typeof context !== 'undefined' ? context : this, null);
						}
					},
					setFirst: function (value) {
						// No implementaion
					},
					removeFirst: function () {
						androidapp.removeFirstFromOfflineQueue();
					},
					//context is optional
					getSize: function (callback, context) {
						callback.call(typeof context !== 'undefined' ? context : this, androidapp.getOfflineQueueSize());
					},
					_serializeValue: _.bind(this._serializeValue, this),
					_deserializeValue: _.bind(this._deserializeValue, this)

				};
			}
			else if (mode == "windowsapp") {
				this.queue = {
					addValue: function (value) {
						window.windowsapp.addValue(this._serializeValue(value), currentProject.id);
					},
					clear: function () {
						window.windowsapp.clearOfflineQueue();
					},
					//context is optional
					getProject: function (callback, context) {
						callback.call(typeof context !== 'undefined' ? context : this, window.windowsapp.getCurrentProjectID());
					},

					//context is optional
					getFirst: function (callback, context) {
						try {
							callback.call(typeof context !== 'undefined' ? context : this, this._deserializeValue(window.windowsapp.getFirstFromOfflineQueue()));
						} catch (e) {
							if (window.onerror) {
								window.onerror(e.message, 'offlineQueue:getFirst:window.windowsapp.call', null, null, e);
							}
							// if (window.oxcatchfaileddeserialize) {
							// 	window.oxcatchfaileddeserialize();
							// }
							callback.call(typeof context !== 'undefined' ? context : this, null);
						}
					},
					setFirst: function (value) {
						// No implementaion
					},
					removeFirst: function () {
						window.windowsapp.removeFirstFromOfflineQueue();
					},
					//context is optional
					getSize: function (callback, context) {
						callback.call(typeof context !== 'undefined' ? context : this, window.windowsapp.getOfflineQueueSize());
					},
					_serializeValue: _.bind(this._serializeValue, this),
					_deserializeValue: _.bind(this._deserializeValue, this)
				};
			}
			else if (mode == "localstorage") {
				this.queue = {
					addValue: function (value) {
						window.localStorage['oq-project'] = currentProject.id;
						var idx = this._lastIdx();
						if (idx === null) {
							idx = 0;
						} else {
							idx++;
						}
						window.localStorage[this._toKey(idx)] = this._serializeValue(value);
					},
					clear: function () {
						delete window.localStorage['oq-project'];
						_.each(this._keyArr(), function (key) {
							delete window.localStorage[key];
						});
					},
					//context is optional
					getProject: function (callback, context) {
						callback.call(typeof context !== 'undefined' ? context : this, window.localStorage['oq-project']);
					},
					//context is optional
					getFirst: function (callback, context) {
						var key = this._toKey(this._firstIdx());
						var value = null;
						try {
							value = this._deserializeValue(window.localStorage[key]);
						} catch (e) {
							window.localStorage.removeItem(key);
							this.getSize(function(size) {
								if (size > 0) {
									this.getFirst(callback, context);
								} else {
									callback.call(typeof context !== 'undefined' ? context : this, null);
								}
							}, this);

							return;
						}

						//only if deserializeValue didn't throw an exception
						callback.call(typeof context !== 'undefined' ? context : this, value);
					},
					removeFirst: function () {
						window.localStorage.removeItem(this._toKey(this._firstIdx()));
					},
					//context is optional
					getSize: function (callback, context) {
						callback.call(typeof context !== 'undefined' ? context : this, this._keyArr().length);
					},
					setFirst: function (value) {
						window.localStorage[this._toKey(this._firstIdx())] = this._serializeValue(value);
					},
					_keyArr: function () {
						return _.filter(_.keys(window.localStorage), _.bind(RegExp.prototype.test, /^d\d+$/));
					},
					_idxArr: function () {
						return _.map(this._keyArr(), function (key) { return parseInt(key.substr(1)); });
					},
					_firstIdx: function () {
						var arr = this._idxArr();
						if (arr.length == 0) {
							return null;
						}
						return _.min(arr);
					},
					_lastIdx: function () {
						var arr = this._idxArr();
						if (arr.length == 0) {
							return null;
						}
						return _.max(arr);
					},
					_toKey: function (idx) {
						return 'd' + idx;
					},
					_serializeValue: _.bind(this._serializeValue, this),
					_deserializeValue: _.bind(this._deserializeValue, this)
				};
			}
			else {
				this.queue = {
					store: [],
					addValue: function (value) {
						this.project = currentProject.id;
						this.store.push(value);
					},
					getProject: function (callback, context) {
						callback.call(typeof context !== 'undefined' ? context : this, this.project);
					},
					clear: function () {
						this.store = [];
					},
					//context is optional
					getFirst: function (callback, context) {
						callback.call(context, this.store[0]);
					},
					setFirst: function (value) {
						this.store[0] = value;
					},
					removeFirst: function () {
						this.store.splice(0, 1); //remove first element
					},
					//context is optional
					getSize: function (callback, context) {
						callback.call(typeof context !== 'undefined' ? context : this, this.store.length);
					}
				};
			}

			this.listenTo(watchdog, 'change:connected', this.online);
			if (typeof androidapp === 'undefined' || navigator.userAgent.match(/(electron)/i) != null) {
				$(window).on('beforeunload', this.beforeunload);
			}
		},

		_deserializeValue: function (str) {
            var result = JSON.parse(str);
            if (_.isArray(result) && result.length > 2 && result[1].type && result[1].type == 'Defect') {
                switch (result[0]) {
                    case 'delete':
                        var defect = window.currentDefects.get(result[1].id);
                        if (defect) { //defect still present --> new session, need to delete from collection
                            defect.trigger('destroy', defect, defect.collection);
                            result[1] = defect;
                            result[2].success = function (resp) {
                                if (!defect.isNew()) defect.trigger('sync', defect, resp);
                            };
                        } else { //defect already deleted (same session) --> only sync trigger
                            result[1].trigger = _.noop;
                        }
                        break;
                    case 'create':
                        var defect = window.currentDefects._byId[result[1].cid];
                        if (!defect || defect.id != result[1].id) {
                            defect = new window.Defect();
                            defect.attributes = result[1].attributes;
                            window.currentDefects.add(defect);
                        } else {
                            defect.attributes = result[1].attributes;
                        }
                        result[1] = defect;
                        result[2].success = function (resp) {
                            var serverAttrs = defect.parse(resp);
                            if (_.isObject(serverAttrs) && !defect.set(serverAttrs)) {
                                return false;
                            }
                            defect.trigger('sync', defect, resp);
                        };
                        break;
                    case 'update':
                        var defect = window.currentDefects.get(result[1].id);
                        if (defect) {
                            defect.attributes = result[1].attributes;
                            result[1] = defect;
                            result[2].success = function (resp) {
                                var serverAttrs = defect.parse(resp);
                                if (_.isObject(serverAttrs) && !defect.set(serverAttrs)) {
                                    return false;
                                }
                                defect.trigger('sync', defect, resp);
                            };
                        } else {
                            result[1].trigger = _.noop;
                            result[2].success = _.noop;
                        }
                        break;
                }
            } else if (_.isArray(result) && result.length > 2 && result[1].type && result[1].type == 'Inspection') {
               switch (result[0]) {
                   case 'create':
                   case 'update':
                       //var inspection = window.currentInspections._byId[result[1].cid];
                       var inspection = null; //FIXME: you will get problems here, because the current data is not injected to the current data object
                       if (!inspection || inspection.id != result[1].id) {
                           inspection = new window.NewInspection();
                           inspection.attributes = result[1].attributes;
                           window.currentInspections.add(inspection);
                       } else {
                           inspection.attributes = result[1].attributes;
                       }
                       result[1] = inspection;
                       result[2].success = function (resp) {
                           var serverAttrs = inspection.parse(resp);
                           if (_.isObject(serverAttrs) && !inspection.set(serverAttrs)) {
                               return false;
                           }
                           inspection.trigger('sync', inspection, resp);
                       };
                       break;
               }
            }
            return result;
        },

        _serializeValue: function (value) {
            if (_.isArray(value) && value.length > 1 && value[1] instanceof window.Defect) {
                value[1] = {
                    type:       'Defect',
                    id:         value[1].isNew() ? null : value[1].id,
                    cid:        value[1].cid,
                    attributes: value[1].attributes,
                    url: 		_.result(value[1], 'url')
                };
            } else if (_.isArray(value) && value.length > 1 && value[1] instanceof window.NewInspection) {
               value[1] = {
                   type:       'Inspection',
                   id:         value[1].id,
                   cid:        value[1].cid,
                   attributes: value[1].attributes
               };
            }
            return JSON.stringify(value);
        },

		sync: function (method, model, options) {
			options = options || {};
			options.offlineQueueRequest = true;
			options.timeout = this.timeout;
			if (method == 'read') {
				return interactiveSync.sync(method, model, options);
			}
			var quotaExceeded = false;
			var args = [ method, model, options ];

			try{
				this.queue.addValue(args);
			} catch(e) {
				switch(e.code) {
					case 22:
						quotaExceeded = true;
						alert("quata Exceeded");
						break;
					case 1014:
						// Firefox
						if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
							quotaExceeded = true;
						}
						alert("quata Exceeded");
						break;
				}
				if(quotaExceeded) {
					//console.warn(e);
					//console.log(window.currentDefects)
					window.alertPopup(user.translate('notification.popup.errorqueue'));
					return $.Deferred().reject();
				}
			}

			this.online();
			return $.Deferred().resolve();
		},

		online: function () {
			if (!currentProject.id || currentDefects.project != currentProject.id || currentDefects.pending) {
				return;
			}

			this.queue.getSize(function(size) {
				this.set('pending', size);
				if (size == 0) {
					watchdog.set('canUpdate', true);
					return; //nothing to do
				}

				watchdog.set('canUpdate', false);

				//don't sync when offline
				if (!watchdog.isConnected()) {
					return
				}

				//prevent multiple parallel syncs
				if (this.get('syncing')) {
					return;
				}
				this.set('syncing', true);

				//(re)run oldest (first) entry
				this.queue.getFirst(function(args) {
					var deferred;
					if (_.isObject(args)) {
						deferred = Backbone.sync.apply(Backbone, args);
					} else {
						deferred = $.Deferred().resolve();
					}
					deferred
					.done(_.bind(function () {
						//on success

						if (_.isObject(args) && args.length && args.length > 1 && args[1].set) {
							args[1].set({
								statusUnchanged:   false,
								agStatusUnchanged: false,
								anStatusUnchanged: false
							});
						}

                        console.log('args', args);
						this.queue.removeFirst();
						if (args && args[2] && args[2].oaRequestId) {
						    Backbone.Events.trigger('multipleDefectSynced', args[2].oaRequestId);
                        }
						this.queue.getSize(function(size) {

							if (size > 0) {
								_.defer(this.online);//try next one
							} else {
								//sync done msg
								this.set('pending', size);
							}
						}, this);
					}, this))
					.fail(_.bind(function (xhr, textStatus, errorThrown) {
						//on fail

						if (xhr && xhr.status == 400 && args.length > 1) {
							var data = {
								message: textStatus + ' ' + errorThrown,
								source: args[0] + ' ' + JSON.stringify(args[1].toJSON())
							};
							iosapp.getsysteminfo(function (systeminfo) {
								data.systeminfo = systeminfo;
								$.ajax({
									type: 'POST',
									url:  '/api/error',
									data: data,
									global: false
								});
							});
							this.queue.removeFirst();
							this.queue.getSize(function(size) {
								if (size > 0) {
									_.defer(this.online);//try next one
								} else {
									//sync done msg
									this.set('pending', size);
								}
							}, this);
							window.alertPopup(user.translate('notification.popup.error400'));
							return;
						}

						delete args[2].xhr;//delete options.xhr to make request repeatable
						this.queue.setFirst(args);
						if (xhr.status == 403) {//try again after login
							Backbone.Events.once('loginSuccess', this.online, this);
							return;
						}
					}, this))
					.always(_.bind(function () {
						this.set('syncing', false);
					}, this));
				}, this);
			}, this);
		},
		
		preventLogout: function () {
			if(this.get('pending') > 0) {
				$('.logout').addClass('ui-disabled')
			} else {
				$('.logout').removeClass('ui-disabled');
			}
		},

		beforeunload: function (e) {
			if ($('body').data('cancelbeforeunload')) {
				return;
			}
			e = e || window.event;
			var pending = this.get('pending');
			if (!currentProject.isNew() || pending > 0) {
				var msg = (pending > 0)
					? user.translate('closetab.pendingdefects').replace('%d', pending)
					: user.translate('closetab');
				if (e) {
					e.returnValue = msg;
				}
				return msg;
			}
		}

	}))();
});
