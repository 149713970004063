// Copyright 2014, Open Experience GmbH. All rights reserved.

define([
    '../lib/jquery-1.11.0', '../lib/backbone-1.1.2', './PersonCSVHtml', '../model/user', '../model/persons', '../model/currentProject', '../lib/moment-2.5.1'
], function ($, Backbone, Template, user, persons, currentProject, moment) {
    'use strict';

    return Backbone.View.extend({

        id: 'personcsv',

        attributes: {
            'data-role': 'page'
        },

        events: {

        },

        template: _.template(Template),
        initialize: function (options) {
            this.router = options.router;

            this.$el.html(this.template({ t: user.translate })).appendTo($.mobile.pageContainer);

            this.$addPersonPopup = this.$('.addPersonPopup');
            this.$firstNamePerson1Textarea = this.$('.addPersonPopup .firstNamePerson1');
            this.$surnamePerson1Textarea = this.$('.addPersonPopup .surnamePerson1');
            this.$addressPerson1Textarea = this.$('.addPersonPopup .addressPerson1');
            this.$phonePerson1Textarea = this.$('.addPersonPopup .phonePerson1');
            this.$mobilePerson1Textarea = this.$('.addPersonPopup .mobilePerson1');
            this.$emailPerson1Textarea = this.$('.addPersonPopup .emailPerson1');
            this.$sexPerson1Textarea = this.$('.addPersonPopup .sexPerson1');

            this.$typePerson1Textarea = this.$('.addPersonPopup .typePerson1');
            this.$firstNamePerson2Textarea = this.$('.addPersonPopup .firstNamePerson2');
            this.$surnamePerson2Textarea = this.$('.addPersonPopup .surnamePerson2');
            this.$addressPerson2Textarea = this.$('.addPersonPopup .addressPerson2');
            this.$phonePerson2Textarea = this.$('.addPersonPopup .phonePerson2');
            this.$mobilePerson2Textarea = this.$('.addPersonPopup .mobilePerson2');
            this.$emailPerson2Textarea = this.$('.addPersonPopup .emailPerson2');
            this.$sexPerson2Textarea = this.$('.addPersonPopup .sexPerson2');
            this.$typePerson2Textarea = this.$('.addPersonPopup .typePerson2');

            this.$peopleTable = this.$('.peopleTable');
        },

        render: function () {
            this.$peopleTable.html('');
            if (persons.length === 1 && persons.at(0).get('id') === "-1") {
                alertPopup(user.translate('gdpr.error'));
                return;
            }
            var loc = this.dfs(currentProject.get('contactIndex') - 1, currentProject.get('unitTree').children, []);
            for (var i = 0; i < persons.length; i++) {
                _.each(loc, function(locElement) {
                    if (locElement.path === persons.at(i).getPath()) {
                        if (!locElement.persons) locElement.persons = [];
                        locElement.persons.push(persons.at(i));
                    }
                }.bind(this));
            }
            var i = 0;
            _.each(loc, function(locElement, i) {
                var color = i % 2 === 0 ? 'white' : '#f1f1f1'
                this.$peopleTable.append('<tr class="persondiv_' + i + '" style="margin-bottom: 8px; text-align: left; background-color:' + color + '">' + '<td style=" padding: 5px;">' + locElement.title + ": " + '</td>'
                      + this.personToString(locElement.persons, i) + '</tr>');
                this.$('.persondiv_' + i).on('vclick', _.bind(function (e) {
                    this.showAddPersonPopup(e, locElement.persons);
                    this.$('.addPersonPopup .buttons .cancel').off('vclick');
                    this.$('.addPersonPopup .buttons .cancel').on('vclick', function() {
                       //this.closeAddPersonPopup();
                        var isEmpty = this.checkIfAllFieldsAreEmpty(this);
                        if(isEmpty===true)
                        {
                            this.closeAddPersonPopup();
                        }
                        else {
                            this.showCloseConfirmPopup(this);
                        }
                    }.bind(this));
                    this.$('.addPersonPopup .buttons .send').off('vclick');
                    this.$('.addPersonPopup .buttons .send').on('vclick', _.bind(function (e) {
                        this.uploadPerson(e, locElement.persons, locElement.title.split(' > '));
                    }, this));
                }, this));
                ++i;
            }.bind(this));
            this.$('.ui-popup-container').css('height', '100%');
        },

        closeAddPersonPopup: function (e) {
            this.resetPersonTextarea();
            this.$addPersonPopup.popup({afterclose: null});
            this.$addPersonPopup.popup('close');
        },

        showAddPersonPopup: function (e, personArr) {
            e && e.preventDefault();
            e && e.stopPropagation();
            this.fillPersonTextarea(personArr);
            this.$addPersonPopup.popup({dismissible: false});
            this.$addPersonPopup.popup('open');
        },
        checkIfAllFieldsAreEmpty: function(data) {
            if(data.$firstNamePerson1Textarea.val())
            {
                return false;
            }
            if(data.$surnamePerson1Textarea.val())
            {
                return false;
            }
            if(data.$addressPerson1Textarea.val())
            {
                return false;
            }
            if(data.$phonePerson1Textarea.val())
            {
                return false;
            }
            if(data.$mobilePerson1Textarea.val())
            {
                return false;
            }
            if(data.$emailPerson1Textarea.val())
            {
                return false;
            }
            if(data.$sexPerson1Textarea.val())
            {
                return false;
            }
            if(data.$typePerson1Textarea.val())
            {
                return false;
            }
            if(data.$firstNamePerson2Textarea.val())
            {
                return false;
            }
            if(data.$surnamePerson2Textarea.val())
            {
                return false;
            }
            if(data.$addressPerson2Textarea.val())
            {
                return false;
            }
            if(data.$phonePerson2Textarea.val())
            {
                return false;
            }
            if(data.$mobilePerson2Textarea.val())
            {
                return false;
            }
            if(data.$emailPerson2Textarea.val())
            {
                return false;
            }
            if(data.$sexPerson2Textarea.val())
            {
                return false;
            }
            if(data.$typePerson2Textarea.val())
            {
                return false;
            }
            return true;
        },
        showCloseConfirmPopup: function (data) {
            var popup = document.getElementById('confirmCancelPopup');
            console.log(popup);
            popup.style.display = 'flex';
            document.getElementById('confirmCancelPopupCloseButton').onclick = function (e) {
                popup.style.display = 'none';
                data.closeAddPersonPopup();
            };
            document.getElementById('confirmCancelPopupCancelButton').onclick = function (e) {
                popup.style.display = 'none';
            };
        },

        nullifyAttributes: function (attribute) {
            return user.translate('person.notGiven') === attribute ? null : attribute;
        },

        uploadPerson: function (e, personArr, location) {
            e.preventDefault();
            e.stopPropagation();
            var hasFirst = Array.isArray(personArr) && personArr.length > 0;
            var has2nd = Array.isArray(personArr) && personArr.length > 1;
            var method1 = hasFirst ? 'PUT' : 'POST';
            var method2 = has2nd ? 'PUT' : 'POST';

            this.$pickDeadlineForSelectedDefects = this.$('#deadline-for-selected-defects-picker').parents().eq(0);


            var data1 = {
                id: hasFirst && personArr[0].get('id') ? personArr[0].get('id') : null,
                firstName: this.nullifyAttributes(this.$firstNamePerson1Textarea.val()),
                surname: this.nullifyAttributes(this.$surnamePerson1Textarea.val()),
                address: this.nullifyAttributes(this.$addressPerson1Textarea.val()),
                phone: this.nullifyAttributes(this.$phonePerson1Textarea.val()),
                mobile: this.nullifyAttributes(this.$mobilePerson1Textarea.val()),
                email: this.nullifyAttributes(this.$emailPerson1Textarea.val()),
                sex: this.nullifyAttributes(this.$sexPerson1Textarea.val()),
                type: this.nullifyAttributes(this.$typePerson1Textarea.val()),
                expirationDate: (new Date(this.$('.deadlineChooserPerson').find('input[type=date]').val()).getTime() / 1000) | 0,
            };

            var data2 = {
                id: has2nd && personArr[1].get('id') ? personArr[1].get('id') : null,
                firstName: this.nullifyAttributes(this.$firstNamePerson2Textarea.val()),
                surname: this.nullifyAttributes(this.$surnamePerson2Textarea.val()),
                address: this.nullifyAttributes(this.$addressPerson2Textarea.val()),
                phone: this.nullifyAttributes(this.$phonePerson2Textarea.val()),
                mobile: this.nullifyAttributes(this.$mobilePerson2Textarea.val()),
                email: this.nullifyAttributes(this.$emailPerson2Textarea.val()),
                sex: this.nullifyAttributes(this.$sexPerson2Textarea.val()),
                type: this.nullifyAttributes(this.$typePerson2Textarea.val()),
                expirationDate: (new Date(this.$('.deadlineChooserPerson').find('input[type=date]').val()).getTime() / 1000) | 0,
            };


            function addPersonToStruc (ids, location) {

                return $.ajax({
                    url:  '/onlineBauabnahme/api/projectStructs/' + user.get('customer') + '/' + currentProject.get('id') + '?personIds=' + ids.join(','),
                    type: 'POST',
                    data: JSON.stringify(location),
                    contentType: 'application/json'
                }).then(_.bind(function (result) {

                    this.$addPersonPopup.popup({
                        dismissible: false,
                        afterclose: _.bind(function () {

                            this.$addPersonPopup.popup({afterclose: null});

                            persons.setProject(currentProject.get('id'))
                                .then(_.bind(function (e) {

                                    this.resetPersonTextarea();

                                    if (persons.length === 0)
                                        this.showAddPersonPopup(e);

                                }, this))
                                .fail(function (e) {
                                    alertPopup(user.translate('defect.persons.fetch.fail'));
                                });
                        }, this)
                    }).popup('close');
                }, this))
                    .fail(function () {
                        alertPopup(user.translate('defect.persons.send.fail'));
                    }.bind(this));
            }

            $.ajax({
                url:  '/onlineBauabnahme/api/persons' + (hasFirst ? '/' + personArr[0].get('id') : ''),
                type: method1,
                data: JSON.stringify(data1),
                contentType: 'application/json',
            })
                .done(function (response1) {
                    $.ajax({
                        url:  '/onlineBauabnahme/api/persons' + (has2nd ? '/' + personArr[1].get('id') : ''),
                        type: method2,
                        data: JSON.stringify(data2),
                        contentType: 'application/json',
                    })
                        .then(_.bind(function (response2) {
                            _.bind(addPersonToStruc, this)([response1.id, response2.id], location)
                                .then(_.bind(function () {
                                    persons.setProject(currentProject.get('id'), true)
                                        .then(_.bind(function () {
                                           this.render();
                                        }, this));
                                }, this));
                        }, this))
                        .fail(function () {
                            alertPopup(user.translate('defect.persons.send.fail'));
                        }.bind(this));

                }.bind(this))
                .fail(function () {
                    alertPopup(user.translate('defect.persons.send.fail'));
                }.bind(this));

            this.resetPersonTextarea();
        },

        getExpirationDate: function (personsArr) {
            var date = new Date();
            if (Array.isArray(personsArr) && personsArr.length > 0)
                date = new Date(personsArr[0].get('expirationDate') * 1000);
            else
                date = date.setMonth(date.getMonth() + 3);

            return new Date(date);
        },


        fillPersonTextarea: function (personsArr) {
            function zeroString(string) {
                return string && string !== user.translate('person.notGiven') ? string : '';
            }

            var expirationDate = this.getExpirationDate();
            this.$('.deadlineChooserPerson').find('input[type=date]').val(moment(expirationDate).add(12, 'hours').format("YYYY-MM-DD"));  //43200000 just add half a day to the timestamp to make sure the correct date is displayed since we don't need the exact time.

            if (!Array.isArray(personsArr) || personsArr.length === 0 ) {
                this.resetPersonTextarea();
                return;
            }

            this.$firstNamePerson1Textarea.val(zeroString(personsArr[0].get('firstName')));
            this.$surnamePerson1Textarea.val(zeroString(personsArr[0].get('surname')));
            this.$addressPerson1Textarea.val(zeroString(personsArr[0].get('address')));
            this.$phonePerson1Textarea.val(zeroString(personsArr[0].get('phone')));
            this.$mobilePerson1Textarea.val(zeroString(personsArr[0].get('mobile')));
            this.$emailPerson1Textarea.val(zeroString(personsArr[0].get('email')));
            this.$sexPerson1Textarea.val(zeroString(personsArr[0].get('sex'))).change();
            this.$typePerson1Textarea.val(zeroString(personsArr[0].get('type')));

            if (personsArr.length === 1 ) return;

            this.$firstNamePerson2Textarea.val(zeroString(personsArr[1].get('firstName')));
            this.$surnamePerson2Textarea.val(zeroString(personsArr[1].get('surname')));
            this.$addressPerson2Textarea.val(zeroString(personsArr[1].get('address')));
            this.$phonePerson2Textarea.val(zeroString(personsArr[1].get('phone')));
            this.$mobilePerson2Textarea.val(zeroString(personsArr[1].get('mobile')));
            this.$emailPerson2Textarea.val(zeroString(personsArr[1].get('email')));
            this.$sexPerson2Textarea.val(zeroString(personsArr[1].get('sex'))).change();
            this.$typePerson2Textarea.val(zeroString(personsArr[1].get('type')));
        },

        resetPersonTextarea: function () {
            this.$firstNamePerson1Textarea.val('');
            this.$surnamePerson1Textarea.val('');
            this.$addressPerson1Textarea.val('');
            this.$phonePerson1Textarea.val('');
            this.$mobilePerson1Textarea.val('');
            this.$emailPerson1Textarea.val('');
            this.$sexPerson1Textarea.val('');
            this.$typePerson1Textarea.val('');

            this.$firstNamePerson2Textarea.val('');
            this.$surnamePerson2Textarea.val('');
            this.$addressPerson2Textarea.val('');
            this.$phonePerson2Textarea.val('');
            this.$mobilePerson2Textarea.val('');
            this.$emailPerson2Textarea.val('');
            this.$sexPerson2Textarea.val('').change();
            this.$typePerson2Textarea.val('');

        },

        dfs: function(depth, tree, arr) {
            var i = 0;
            _.each(_.values(tree), function(treeElement) {
                if (depth === 0)
                    arr.push({title: treeElement.title, path: treeElement.path});
                else
                    arr = this.dfs(depth - 1, treeElement.children, arr);
                ++i;
            }.bind(this));
            return arr;
        },



        personToString: function (persons, i) {
            if (!persons || persons.length === 0 || persons[0].isEmpty()) return user.translate('persons.noData');
            var personString = '<td style="font-weight: bold;">' + user.translate('person.primaryContact') + ': ' + '</td>';
            personString =  personString.concat('<td>' + (persons[0].has('firstName') ? (persons[0].get('firstName') + ', ') : '') + '</td>');
            personString =  personString.concat('<td>' + (persons[0].has('surname') ? (persons[0].get('surname') + ', ') : '') + '</td>');
            personString =  personString.concat('<td>' + (persons[0].has('address') ? (persons[0].get('address') + ', ') : '') + '</td>');
            personString =  personString.concat('<td>' + (persons[0].has('phone') ? (persons[0].get('phone') + ', ') : '') + '</td>');
            personString =  personString.concat('<td>' + (persons[0].has('mobile') ? (persons[0].get('mobile') + ', ') : '') + '</td>');
            personString =  personString.concat('<td>' + (persons[0].has('email') ? (persons[0].get('email') + ', ') : '') + '</td>');
            personString =  personString.concat('<td>' + (persons[0].has('sex') ? (user.translate('person.' + persons[0].get('sex')) + ', ') : '') + '</td>');
            personString =  personString.concat('<td>' + (persons[0].has('type') ? (persons[0].get('type') + ', ') : '') + '</td>');

            if (persons.length < 2 || persons[1].isEmpty()) return personString;
            var secondString = " " + '<td style="font-weight: bold;">' + user.translate('person.secondaryContact') + ': ' + '</td>';
            secondString =  secondString.concat('<td>' + (persons[1].has('firstName') ? (persons[1].get('firstName') + ', ') : '') + '</td>');
            secondString =  secondString.concat('<td>' + (persons[1].has('surname') ? (persons[1].get('surname') + ', ') : '') + '</td>');
            secondString =  secondString.concat('<td>' + (persons[1].has('address') ? (persons[1].get('address') + ', ') : '') + '</td>');
            secondString =  secondString.concat('<td>' + (persons[1].has('phone') ? (persons[1].get('phone') + ', ') : '') + '</td>');
            secondString =  secondString.concat('<td>' + (persons[1].has('mobile') ? (persons[1].get('mobile') + ', ') : '') + '</td>');
            secondString =  secondString.concat('<td>' + (persons[1].has('email') ? (persons[1].get('email') + ', ') : '') + '</td>');
            secondString =  secondString.concat('<td>' + (persons[1].has('sex') ? (user.translate('person.' + persons[1].get('sex')) + ', ') : '') + '</td>');
            secondString =  secondString.concat('<td>' + (persons[1].has('type') ? (persons[1].get('type') + ', ') : '') + '</td>');

            personString = secondString === (" " + user.translate('person.secondaryContact') + ': ') ? personString : personString.concat(secondString);


            return personString;
        },

    });
});
