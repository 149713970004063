define([
	'../lib/jquery-1.11.0', '../lib/lodash-2.4.1.compat', '../lib/backbone-1.1.2', 'moment',
	'../model/Defect',	'../model/currentProject', '../model/subcontractors', '../model/user', '../model/inspections',
	'../helper/interactiveSync', '../helper/watchdog', '../model/proxyInfo'
], function (
	$, _, Backbone, moment,
	Defect, currentProject, subcontractors, user, inspections,
	interactiveSync, watchdog, proxyInfo
) {
	return window.currentDefects = new (Backbone.Collection.extend({ //assign to global variable for usage in offlineQueue

		model: Defect,

		initialize: function () {
			this.listenTo(currentProject, 'change:id', this.resetFilter);

			this.resetFilter();
		},

		url: function () {
			 return  '/onlineBauabnahme/api/defects/' + this.project;
		},

		setProject: function (project) {
			if (project == this.project) {
				if (this.pending) {
					return this.pending;
				} else {
					return $.Deferred().resolve();
				}
			}
			var oldProject = this.project;
			this.project = project;
			if (!project) {
				this.reset();
				return $.Deferred().resolve();
			}
			this.pending = this.fetch({ reset: true })
			.fail(_.bind(function () {
				this.project = oldProject;
			}, this))
			.always(_.bind(function () {
				delete this.pending;
			}, this));
			return this.pending;
		},

		toJSON: function () { //omit attachments
			return this.map(function (model) {
				return _.omit(model.attributes, 'attachments');
			});
		},

		sync: function (method, model, options) {
			return interactiveSync.sync.apply(interactiveSync, [method, model, proxyInfo.syncAddHeaders(options)]);
		},

		fetch: function () {
			watchdog.set('defectLocalDate', watchdog.get('defectDate'));
			return Backbone.Collection.prototype.fetch.apply(this, arguments);
		},

		matchesDetailFilter: function (defect) {
			if (typeof this.detailFilter.external === 'object' && !this.elementFilter.external) {
				if (!_.contains(this.detailFilter.external, String(defect.get('external')))) {
					return false;
				}
			}
			if (typeof this.detailFilter.type === 'object') {
				if (!_.contains(this.detailFilter.type, defect.get('type'))) {
					return false;
				}
			}
			if (this.detailFilter.locations !== 'all') {
				var locationId = defect.get('locationId');
				if (this.detailFilter.locations === 'none') {
					if (locationId) {
						return false;
					}
				} else if (typeof this.detailFilter.locations === 'object') {
					var matches = false;
					for (var i = 0; i < this.detailFilter.locations.length; i++) {
						if (defect.belongsTo(this.detailFilter.locations[i])) {
							matches = true;
							break;
						}
					}
					if (!matches) {
						return false;
					}
				}
			}
			if (this.detailFilter.crews !== 'all') {
				var crew = defect.get('crew');
				if (this.detailFilter.crews === 'none') {
					if (crew) {
						return false;
					}
				} else if (typeof this.detailFilter.crews === 'object') {
					var matches = false;
					for (var i = 0; i < this.detailFilter.crews.length; i++) {
						var filterCrew = this.detailFilter.crews[i];
						if (crew ===  filterCrew) {
							matches = true;
							break;
						}
					}
					if (!matches) {
						return false;
					}
				}
			}
			if (this.detailFilter.subcontractors !== 'all') {
				if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
					var crew = defect.get('crew');
					if (this.detailFilter.subcontractors === 'none') {
						if (crew) {
							return false;
						}
					} else if (typeof this.detailFilter.subcontractors === 'object') {
						if (!_.contains(this.detailFilter.subcontractors, crew)) {
							return false;
						}
					}
				} else {
					var subcontractor = defect.get('subcontractor');
					if (this.detailFilter.subcontractors === 'none') {
						if (subcontractors.validRegexp.test(subcontractor)) {
							return false;
						}
					} else if (typeof this.detailFilter.subcontractors === 'object') {
						if (!_.contains(this.detailFilter.subcontractors, subcontractor)) {
							return false;
						}
					}
				}
			}
			if (this.detailFilter.inspections !== 'all') {
				var inspections = defect.get('inspections');
				if (this.detailFilter.inspections === 'none') {
					if (inspections && inspections.length > 0) {
						return false;
					}
				} else if (typeof this.detailFilter.inspections === 'object') {
					if (_.intersection(this.detailFilter.inspections, inspections).length == 0) {
						var inspections = defect.get('inspections');
						if (!_.contains(this.detailFilter.inspections, -1) || (inspections && inspections.length > 0)) {
							return false;
						}
					}
				}
			}

            if(!this.detailFilter.combinedStatusFilter) {
                if (typeof this.detailFilter.status === 'object') {
                    if (!_.contains(this.detailFilter.status, defect.get('status'))) {
                        return false;
                    }
                }
                if (typeof this.detailFilter.subcontractorStatus === 'object') {
                    if (!_.contains(this.detailFilter.subcontractorStatus, defect.get('subcontractorStatus'))) {
                        return false;
                    }
                }
                if (typeof this.detailFilter.principalStatus === 'object') {
                    if (!_.contains(this.detailFilter.principalStatus, defect.get('principalStatus'))) {
                        return false;
                    }
                }
                if (typeof this.detailFilter.deadline === 'object') {
                    var filterDeadline = this.detailFilter.deadline;
					if(filterDeadline === 'all' || filterDeadline === 'none') return true;
                    var defectDeadline = defect.get('deadline');
                    var deadline = moment();
                    var ddeadline = moment(defectDeadline, 'YYYY.MM.DD');
                    var show = false;
                    if(_.contains(filterDeadline, 'expired')) {
                    	var diff = deadline.diff(ddeadline, 'days'); //If ddeadline is earlier than deadline (ie: in the past), then diff will be > 0. Anything else <= 0;
                    	if(!show) show = diff > 0;
					}
                    if(_.contains(filterDeadline, 'notexpired')) {
                    	var diff = deadline.diff(ddeadline, 'days'); //If ddeadline is earlier than deadline (ie: in the past), then diff will be > 0. Anything else <= 0;
						if(!show) show = diff < 0;
					}
                    if(_.contains(filterDeadline, 'nodate')) {
						if(!show) show = ddeadline.toString().toLowerCase() === 'invalid date';
					}
                    if(_.contains(filterDeadline, 'today')) {
                    	var diff = deadline.diff(ddeadline, 'days'); //If ddeadline is earlier than deadline (ie: in the past), then diff will be > 0. Anything else <= 0;
						if(!show) show = diff === 0;
					}
                    if(!show) return false;
                }
                //
                // if (typeof this.detailFilter.requests === 'object') {
                //     var filterRequests = this.detailFilter.requests;
                //     var defectRequests = defect.get('requests');
                //     if (!_.contains(filterRequests, defectRequests.toString())) {
                //         return false;
                //     }
                // }
                //
				if (typeof this.detailFilter.requests === 'object') {
					var hasEscalationLevel = false;
					var notSent = _.contains(this.detailFilter.requests, 'NotSent');
					if(notSent && this.detailFilter.requests.length === 1) {
						hasEscalationLevel = !defect.get('requestSent');
					} else {
						_.each(this.detailFilter.requests, function (l) {
							var descallevel = defect.get('requests');
							var level = l === 'X' ? 4 : parseInt(l);
							if(descallevel === level && (!notSent || defect.get('requestSent') !== notSent)) hasEscalationLevel = true;
						})
					}
					if(!hasEscalationLevel) return false;
				}
            } else {

				var combinedStatusFilter = this.detailFilter.combinedStatusFilter;
                if (combinedStatusFilter.indexOf('agcost') === 0) {
                	if (!(defect.get('cost') === combinedStatusFilter.substr(2) && defect.get('status') === 'pending')) {
                   		return false;
				   	}
                } else if (combinedStatusFilter.indexOf('cost') === 0) {
                    if (!(defect.get('cost') === combinedStatusFilter.status && defect.get('status') === 'pending')){
                        return false;
                    }
                }  else if (combinedStatusFilter === 'pending-subcontractor-fixed') {
                    if (!(defect.get('subcontractorStatus') === 'fixed' && defect.get('status') === 'pending')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'pending-subcontractor-pending') {
                    if(!(defect.get('subcontractorStatus') === 'pending' && defect.get('status') === 'pending')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'all-principal-rejected') {
                    if(!(defect.get('principalStatus') === 'rejected')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'all-principal-discount') {
                    if(!(defect.get('principalStatus') === 'discount')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'all-principal-accepted') {
                    if (!(defect.get('principalStatus') === 'accepted')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'all-principal-pendingrejected') {
                    if (!(defect.get('principalStatus') === 'pendingrejected')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'done-principal-pending') {
                    if (!(defect.get('principalStatus') === 'pending' && defect.get('status') === 'done')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'all-principal-pending') {
                    if (!(defect.get('principalStatus') === 'pending')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'extrastatusp') {
                    if (!(defect.get('extraStatus') === 'P' && defect.get('principalStatus') === 'rejected' && defect.get('status') === 'fixed')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'extrastatusg') {
                    if ( !(defect.get('extraStatus') === 'G' && defect.get('principalStatus') === 'rejected' && defect.get('status') === 'fixed')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'extrastatusas') {
                    if ( !(defect.get('extraStatusAN') === 'S' && defect.get('subcontractorStatus') === 'fixed' && defect.get('status') === 'fix')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'extrastatusag') {
                    if ( !(defect.get('extraStatusAN') === 'G' && defect.get('subcontractorStatus') === 'fixed' && defect.get('status') === 'fix')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'request1-overdue') {
                    if ( !(defect.get('requests') === 1 && !this.checkIfOverdue(defect, true) && defect.get('status') === 'pending')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'request2-overdue') {
                    if ( !(defect.get('requests') === 2 && !this.checkIfOverdue(defect, true) && defect.get('status') === 'pending')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'agoverdue') {
                    if ( !(!this.checkIfAgOverdue(defect, true) && defect.get('status') === 'pending')){
                        return false;
                    }
                } else if (combinedStatusFilter === 'subcontractor-fixed-commented') {
                    if ( !(defect.get('subcontractorStatus') === 'fixed' && !this.checkIfhasNewComment(defect))){
                        return false;
                    }
                } else if (combinedStatusFilter === 'subcontractor-pending-commented') {
                    if ( !(defect.get('subcontractorStatus') === 'pending' && !this.checkIfhasNewComment(defect))){
                        return false;
                    }
                } else if (combinedStatusFilter === 'subcontractor-has-new-comments') {
                    if ( !(!this.checkIfhasNewComment(defect))){
                        return false;
                    }
                } else if (combinedStatusFilter === 'subcontractor-pending-all-changes') {
                    if ( !(defect.get('subcontractorStatus') === 'pending' && !this.checkAllSubChanges(defect))){
                        return false;
                    }
                } else if (combinedStatusFilter === 'subcontractor-fixed-all-changes') {
                    if ( !(defect.get('subcontractorStatus') === 'fixed' && !this.checkAllSubChanges(defect))){
                        return false;
                    }
                } else if (combinedStatusFilter === 'subcontractor-all-changes') {
					if (!(this.checkAllSubChanges(defect))) {
						return false;
					}
				} else if (combinedStatusFilter === 'principal-all-changes') {
					if (!(this.checkAllPrincipalChanges(defect))) {
						return false;
					}
                } else {
                    if (defect.get('status') !== combinedStatusFilter) {
                        return false;
                    }
				}
			}

			if (typeof this.detailFilter.createdBy === 'object') {
				if (!_.contains(this.detailFilter.createdBy, defect.get('createdBy'))) {
					return false;
				}
			}

			if (typeof this.detailFilter.description === 'object') {
				var descriptionEmpty = !defect.get('description') || /^\s*$/.test(defect.get('description'));
				if (!_.contains(this.detailFilter.description, 'empty') && descriptionEmpty) {
					return false;
				}
				if (!_.contains(this.detailFilter.description, 'notempty') && !descriptionEmpty) {
					return false;
				}
			}

			return true;
		},

		matchesFilter: function (defect) {
			if (!defect) {
				return false;
			}

    			if (this.elementFilter.favourite && !defect.get('isFavourite')) {
				return false;
			}

			//customId
			if (this.elementFilter.customDefects && this.elementFilter.customDefects.length !== 0) {
				if (_.contains(this.elementFilter.customDefects, defect.get('id'))) {
					return true;
				} else {
					return false;
				}
			}

			//external
			if (this.elementFilter.external && !defect.get('external')) {
				return false;
			}

			if (this.detailFilter) {
				return this.matchesDetailFilter(defect);
			}


			//unit
			if (this.elementFilter.regexpUnit) {
				if (!this.elementFilter.regexpUnit.test(defect.get('locationId'))) {
					return false;
				}
			} else if (this.elementFilter.unit && _.contains(this.elementFilter.unit, "null")){ //not specified location
				var locationId = defect.get('locationId') || '';
				var specificLocationWithoutNull = this.elementFilter.unit.substring(0, (this.elementFilter.unit + "").length - 4); // removes null at the end of the string

				if (!(locationId.indexOf(specificLocationWithoutNull) === 0)) { //no need to execute further if there is no match -> performance
					return false;
				}

				var unitTree = currentProject.get('unitTree');

				var selectedFilterLocation = locationId.split("-");
				var selectedFilterLocationLast = selectedFilterLocation[selectedFilterLocation.length - 1];

				var iter = 0;
				var matchFound = false;

				while(unitTree && unitTree.children) { //goes at the end of the unitTree
					unitTree = unitTree.children;

					if (unitTree[selectedFilterLocation[iter]]) {
						unitTree = unitTree[selectedFilterLocation[iter]];
						iter++;
					}
				}
				//checks if there is match between the last locations inside the unitTree and the free input field
				for (var key in unitTree) {
					if (key + "" === selectedFilterLocationLast + "") {
						matchFound = true;
						break;
					}
				}

				if (!matchFound) {
					locationId = locationId.substring(0, locationId.length - selectedFilterLocationLast.length);
				}

				if (locationId !== specificLocationWithoutNull) {
					return false;
				}

			} else if (this.elementFilter.unit /*!== null */ && !defect.belongsTo(this.elementFilter.unit)) {
				return false;
			}
			//subcontractor
			if (this.elementFilter.subcontractor !== null) {
				if (user.isPrincipal() && !currentProject.get('showSubcontractorsToPrincipal')) {
					if (this.elementFilter.subcontractor !== defect.get('crew')) {
						return false;
					}
				} else {
					var defectType = _.find(currentProject.get('types'), function(el) {
						return el.defectTypeId === defect.get('type');
					}.bind(this));

					var subcontractor = defect.get('subcontractor');
					if (this.elementFilter.subcontractor === 'null') {
						if (defectType && defectType.isPrincipal || subcontractors.validRegexp.test(subcontractor)) { //obstruction2
							return false;
						}
					} else if (this.elementFilter.subcontractor != subcontractor) {
						return false;
					}
				}
			}

			//inspection
			if (this.elementFilter.inspection !== null) {
				var inspections = defect.get('inspections');
				if (this.elementFilter.inspection === 'null') {
					if (inspections !== null && inspections.length > 0) {
						return false;
					}
				} else if (inspections === null || !_.contains(inspections, parseInt(this.elementFilter.inspection))) {
					return false;
				}
			}



			//status
			if (this.elementFilter.extraStatus && this.elementFilter.extraStatus != defect.get('es')) {
				return false;
			}
			if (this.elementFilter.extraStatusAN && this.elementFilter.extraStatusAN != defect.get('as') && this.elementFilter.extraStatusAN !== 'G or empty') {
				return false;
			}
			if(this.elementFilter.extraStatusAN === 'G or empty') {
				if(!defect.get('as') || defect.get('as') === 'G') {} else {return false;}
			}
			if (this.elementFilter.status === 'done') {
				if (defect.isStatusPending()) {
					return false;
				}
			} else if (this.elementFilter.status === 'pending') {
				if (!defect.isStatusPending()) {
					return false;
				}
			} else if (this.elementFilter.status !== null && this.elementFilter.status != defect.get('status')) {
				return false;
			}
			if (this.elementFilter.subcontractorStatus !== null && this.elementFilter.subcontractorStatus != defect.get('subcontractorStatus')) {
				return false;
			}
			if (this.elementFilter.principalStatus !== null) {
				var principalStatus = defect.get('principalStatus');
				if (this.elementFilter.principalStatus === 'pendingrejected') {
					if (principalStatus !== 'pending' && principalStatus !== 'rejected') {
						return false;
					}
				} else {
					if (this.elementFilter.principalStatus != principalStatus) {
						return false;
					}
				}
			}

			//type
			if (this.elementFilter.type && this.elementFilter.type != defect.get('type')) {
				return false;
			}

			//cost
			var cost = defect.get('cost');
			if (this.elementFilter.cost) {
				if (this.elementFilter.cost === 'costnone') {
					if (_.isFinite(cost)) {
						return false;
					}
				} else if (this.elementFilter.cost === 'cost0') {
					if (!(_.isFinite(cost) && cost === 0)) {
						return false;
					}
				} else if (this.elementFilter.cost === 'cost1000') {
					if (!(_.isFinite(cost) && cost === 1000)) {
						return false;
					}
				} else if (this.elementFilter.cost === 'costto200') {
					if (!(_.isFinite(cost) && cost < 200)) {
						return false;
					}
				} else if (this.elementFilter.cost === 'costto1000') {
					if (!(_.isFinite(cost) && cost >= 200 && cost < 1000)) {
						return false;
					}
				} else if (this.elementFilter.cost === 'costfrom1000') {
					if (!(_.isFinite(cost) && cost >= 1000 && cost < 5000)) {
						return false;
					}
				} else if (this.elementFilter.cost === 'costfrom5000') {
					if (!(_.isFinite(cost) && cost >= 5000)) {
						return false;
					}
				}
			}

			//cost
			var costAG = defect.get('costAG');
			if (this.elementFilter.costAG) {
				if (this.elementFilter.costAG === 'costnone') {
					if (_.isFinite(costAG)) {
						return false;
					}
				} else if (this.elementFilter.costAG === 'cost0') {
					if (!(_.isFinite(costAG) && costAG === 0)) {
						return false;
					}
				} else if (this.elementFilter.costAG === 'cost1000') {
					if (!(_.isFinite(costAG) && costAG === 1000)) {
						return false;
					}
				} else if (this.elementFilter.costAG === 'costto200') {
					if (!(_.isFinite(costAG) && costAG < 200)) {
						return false;
					}
				} else if (this.elementFilter.costAG === 'costto1000') {
					if (!(_.isFinite(costAG) && costAG >= 200 && costAG < 1000)) {
						return false;
					}
				} else if (this.elementFilter.costAG === 'costfrom1000') {
					if (!(_.isFinite(costAG) && costAG >= 1000 && costAG < 5000)) {
						return false;
					}
				} else if (this.elementFilter.costAG === 'costfrom5000') {
					if (!(_.isFinite(costAG) && costAG >= 5000)) {
						return false;
					}
				}
			}


			//number of requests
            if (this.elementFilter.requests !== null) {
                var requests = defect.get("requests");


                if (this.elementFilter.requests >= 2) {
                	if (requests < 2) {
						return false;
					}
				} else {
					if (this.elementFilter.requests !== requests) {
						return false;
					}
				}
			}

			//overdue
			if (this.elementFilter.overdue !== null) {
				var isOverdue =  this.checkIfOverdue(defect, this.elementFilter.overdue);
				if (!isOverdue) {
					return false;
				}
			}

			//agoverdue
			if (this.elementFilter.agoverdue !== null) {
				var isAgOverdue =  this.checkIfAgOverdue(defect, this.elementFilter.agoverdue);
				if (!isAgOverdue) {
					return false;
				}
			}

			//hasNewSCMT
			if (this.elementFilter.hasNewSCMT !== null) {
                if (!this.checkIfhasNewComment(defect)) {
                    return false;
                }
			}

			//selectAllDefectsChangedBySubcontractor
			if (this.elementFilter.allSubChanges) {
				if (!this.checkAllSubChanges(defect)) {
					return false;
				}
			}

			//selectAllDefectsChangedByPrincipal
			if (this.elementFilter.allPrincipalChanges) {
				if (!this.checkAllPrincipalChanges(defect)) {
					return false;
				}
			}
			
			//Escalation level
			if (this.elementFilter.escalationLevels && this.elementFilter.escalationLevels.length > 0) {
				var hasEscalationLevel = false;
				var notSent = _.contains(this.elementFilter.escalationLevels, 'notsent');
				if (_.contains(this.elementFilter.escalationLevels, 'readyfortermination')) {
					var typeConfig = new Map(_.pairs(currentProject.get('typeConfiguration'))).get(defect.get('type'));
					if (!typeConfig) {
						console.log('ERROR_HELP_ME');
						return false;
					}
					if (defect.get('requests') === 4 || !typeConfig.terminationLevel) return false;
					var lastNonTerminationLevel = 1;
					if (typeConfig.thirdEscalationLevel) lastNonTerminationLevel = 3;
					if (!typeConfig.thirdEscalationLevel && typeConfig.secondEscalationLevel) lastNonTerminationLevel = 2;
					if (defect.get('requests') < lastNonTerminationLevel) return false;
				} else {
					if (notSent && this.elementFilter.escalationLevels.length === 1) {
						hasEscalationLevel = !defect.get('requestSent');
					} else {
						_.each(this.elementFilter.escalationLevels, function (l) {
							var levels = ['one', 'two', 'three', 'X'];
							var descallevel = levels[defect.get('requests') - 1];
							if (descallevel === l && (!notSent || defect.get('requestSent'))) hasEscalationLevel = true;
						});
					}
					if(!hasEscalationLevel) return false;
				}
			}
			
			if (this.elementFilter.creationDateFilter && (this.elementFilter.creationDateFilter.from || this.elementFilter.creationDateFilter.to)) {
				var from = (this.elementFilter.creationDateFilter.from ? this.elementFilter.creationDateFilter.from.getTime() : null);
				var to = (this.elementFilter.creationDateFilter.to ? this.elementFilter.creationDateFilter.to.getTime() : null);
				if (defect.get('created')) {
					var creationDate = new Date(defect.get('created')).getTime();
					if (from && to) {
						if (creationDate < from || creationDate > to) return false;
					} else if (from) {
						if (creationDate < from) return false;
					} else {
						if (creationDate > to) return false;
					}
				}
			}
			
			//Deadline filter
			if (this.elementFilter.deadlineFilters && this.elementFilter.deadlineFilters.length > 0) {
				var filterDeadline = this.elementFilter.deadlineFilters;
				var defectDeadline = defect.get('deadline');
				var today = moment().startOf('day');
				var deadline = moment(defectDeadline, 'YYYY.MM.DD');
				var show = false;
				if (_.contains(filterDeadline, 'expired')) {
					var diff = today.diff(deadline, 'days'); //If deadline is before today (ie: in the past), then diff will be > 0. Anything else <= 0;
					if (!show) show = diff > 0;
				}
				if (_.contains(filterDeadline, 'notexpired')) {
					var diff = today.diff(deadline, 'days'); //If deadline is before today (ie: in the past), then diff will be > 0. Anything else <= 0;
					if (!show) show = diff < 0;
				}
				if (_.contains(filterDeadline, 'nodate')) {
					if (!show) show = deadline.toString().toLowerCase() === 'invalid date';
				}
				if (_.contains(filterDeadline, 'today')) {
					var diff = today.diff(deadline, 'days'); //If deadline is before today (ie: in the past), then diff will be > 0. Anything else <= 0;
					if (!show) show = diff === 0;
				}
				if (!show) return show;
			}
			
			if (this.elementFilter.externalFilter) {
				if (this.elementFilter.externalFilter === 2) {
					if (defect.get('external')) return false;
				} else if (this.elementFilter.externalFilter === 1) {
					if (!defect.get('external')) return false;
				}
			}

			//search
			//return only true value for search mode
			if (this.elementFilter.search) {

				if (!(_.contains(this.elementFilter.search, ".locSpecificTag."))) { // specifies whether the user is interested in depth first search
					var search = this.elementFilter.search.trim().toLowerCase();
					return this.tryInputMatch(defect, search);
				} else {
					if (this.elementFilter.unit && !defect.belongsTo(this.elementFilter.unit)) {
						if (this.elementFilter.unit != 'null') {
							return false;
						}
					}

					var locSpecificTag = ".locSpecificTag.";
					var search = this.elementFilter.search.substring(0, this.elementFilter.search.length - locSpecificTag.length).toLowerCase();

					return this.tryInputMatch(defect, search);
				}

			}
			//search should stop here, no further matching (don't have to return always true)
			return true;
		},
		tryInputMatch: function(defect, search) {
			if (!search) {
				return true;
			}
			var twiceSyncRegex = /^[A-Za-z]*\d+-\d+$/;
			var isNumber = /^\d+$/.test(search); // this is required since if the input is ^\d\\.\s\d.\w+ it will recognized simply as \d and thus not work as intended.

			var num;
			if (isNumber) {
				num = window.parseInt(search);
			} else {
				num = NaN;
			}

			var eid = defect.get('externalId');
			if (!_.isNaN(num)) {
				if (eid && !user.isSubcontractor()) {
					var eidnum;
					if (eid.match(twiceSyncRegex)) {
						eidnum = eid.split('-')[0].replace(/[^0-9]/g, '');
					} else {
						eidnum = eid.replace(/[^0-9]/g, '');
					}
					if (eidnum) {
						if (num === window.parseInt(eidnum)) {
							return true;
						}
					}
				}
				if(num === defect.get('projectId')){
					return true;
				}
			}

			if (eid && !user.isSubcontractor()) {
				if (eid.toLowerCase() === search) {
					return true;
				}
				if (eid.match(twiceSyncRegex)) { //for twice synchronized defects
					if (eid.toLowerCase().split('-')[0] === search) {
						return true;
					}
				}
			}
			if (!this.elementFilter.external && /^n\d+$/.test(search)) {
				if(window.parseInt(search.substr(1), 10) === defect.get('projectId')) {
					return true;
				}
			}

			if(defect.getLabel(this.elementFilter.external).toLowerCase().indexOf(search) !== -1) {
				return true;
			}
			//all test failed so item is out of result list.
			return false;
		},
		checkIfOverdue: function (defect, overdue) {
            var deadline = defect.get("deadline");
            if (!deadline) {
                return false;
            }
            var today = new Date().toISOString().substr(0, 10);
            if (overdue) {
                if (deadline >= today) {
                    return false;
                }
            } else {
                if (deadline < today) {
                    return false;
                }
            }

            return true;
		},
		checkIfAgOverdue: function (defect, agoverdue) {
            var agdeadline = defect.get("agdeadline");
            if (!agdeadline) {
                return false;
            }
            var today = new Date().toISOString().substr(0, 10);
            if (agoverdue) {
                if (agdeadline >= today) {
                    return false;
                }
            } else {
                if (agdeadline < today) {
                    return false;
                }
            }

            return true;
		},

		checkIfhasNewComment: function (defect) {
            var comments = defect.get("scmt");
            var cmtChange = defect.get("acs");
            if (cmtChange && comments  && comments.length > 0 && cmtChange ==="S") {
                return true;
            } else {
                return false;
            }
		},

        checkAllSubChanges: function (defect) {
            var comments = defect.get("scmt");
            var cmtChange = defect.get("acs");
            if ((cmtChange && comments && comments.length > 0 && cmtChange === "S") || (defect.get('as') === 'S')) {
                return true;
            } else {
                return false;
            }
        },

		checkAllPrincipalChanges: function (defect) {
			var comments = defect.get('cmt');
			var cmtChange = defect.get('cs');
			if ((cmtChange && comments && comments.length > 0 && cmtChange === 'P') || (defect.get('es') === 'P')) {
				return true;
			} else {
				return false;
			}
		},

		filtered: function () {
			return this.filter(this.matchesFilter, this);
		},

		navigationIDs: function (model) {

			if (this.defectsGrouped) {
				var flatList = [];
                if(this.groupOrder) {
                    _.forEach(this.groupOrder, _.bind(function(val) {
                       flatList = flatList.concat(this.defectsGrouped[val]);
                    }, this))
                } else {
                    flatList =  _.reduce(
                        _.sortBy(_.pairs(this.defectsGrouped), function (item) { return item[0]; }),
                        function (memo, item) {
                            return _.flatten([ memo, _.filter(item[1], function (defect) {
                                return !defect.isNew() && this.matchesFilter(defect);
                            }, this) ]);
                        },
                        [],
                        this
                    );
                }

				if (flatList.length == 0) {
					return {
						previous: null,
						next: null
					};
				}
				if (!model || model.isNew()) {
					return {
						previous: _.last(flatList).get('projectId'),
						next: null
					};
				}
				var index = _.indexOf(flatList, model);
				return {
					previous: index > 0 ? flatList[index - 1].get('projectId') : null,
					next:     index < flatList.length - 1 ? flatList[index + 1].get('projectId') : null
				};
			}
			if (!model || model.isNew()) {
				var previous = this.max(function (item) { return item.get('projectId'); });
				return {
					previous: _.isObject(previous) ? previous.get('projectId') : null,
					next: null
				};
			}
			var previous = null;
			for (var i = model.get('projectId') - 1; i >= 0; i--) {
				var obj = this.get(i);
				if (obj && this.matchesFilter(obj)) {
					previous = i;
					break;
				}
			}
			var next = null, max = this.max(function (item) { return item.get('projectId'); });
			if (_.isObject(max)) {
				for (var i = model.get('projectId') + 1; i <= max.get('projectId'); i++) {
					var obj = this.get(i);
					if (obj && this.matchesFilter(obj)) {
						next = i;
						break;
					}
				}
			}
			return {
				previous: previous,
				next: next
			}
		},

		resetFilter: function () {
			this.elementFilter = this.freshFilter();
			this.detailFilter  = null;
		},

		freshFilter: function () {
			return {
				external:            user.isPrincipal() || !!(this.elementFilter && !_.isUndefined(this.elementFilter.external) && this.elementFilter.external),
				search:              null,
				unit:                null,
				regexpUnit:          null,
				subcontractor:       null,
				inspection:          null,
				status:              null,
				subcontractorStatus: null,
				principalStatus:     null,
				cost:                null,
				costAG:              null,
				extraStatus:         null,
				extraStatusAN:       null,
				type:                null,
				requests:			 null,
				creationDateFilter:	 null,
				overdue:			 null,
				agoverdue:			 null,
				hasNewSCMT:          null,
				allSubChanges:       null,
				allPrincipalChanges: null,
				customDefects:		 null

			};
		},

		numberDetect: /^.*?(\d+).*?$/,

		groupOrderDefectsBy: function (group, order) {
			//get filtered defects
			var defects = this.filtered();
			//order them
			var groupLabel;
			switch (order) {
			case 'numberdesc':
				defects = _.sortBy(defects, function (model) {
					var num, extracted;
					return -(
						this.elementFilter.external && model.get('external')
						? (_.isFinite(extracted = parseInt((num = (model.get('externalId') || '')).replace(this.numberDetect, '$1'))) ? extracted : num)
						: model.id
					);
				}, this);
				groupLabel = user.translate('defects.grouplabel.numberdesc');
				break;
			case 'numberasc':
				defects = _.sortBy(defects, function (model) {
					var num, extracted;
					return this.elementFilter.external && model.get('external')
						? (_.isFinite(extracted = parseInt((num = (model.get('externalId') || '')).replace(this.numberDetect, '$1'))) ? extracted : num)
						: model.id;
				}, this);
				groupLabel = user.translate('defects.grouplabel.numberasc');
				break;
			case 'created':
				defects = _.sortBy(defects, function (model) {
					return model.has('created') ? model.get('created') : new Date(0);
				}, this);
				groupLabel = user.translate('defects.grouplabel.created');
				break;
			case 'cost':
				var costType = currentProject.get('costType');
				if (costType === 'SEPARATE' && this.elementFilter.external) {
					defects = _.sortBy(defects, function (model) {
						return model.has('costAG') ? -model.get('costAG') : 0;
					}, this);
				} else {
					defects = _.sortBy(defects, function (model) {
						return model.has('cost') ? -model.get('cost') : 0;
					}, this);
				}
				groupLabel = (costType === 'GLOBAL')
					? user.translate('defects.grouplabel.cost.global')
					: costType === 'SPECIFIC'
					  ? user.translate('defects.grouplabel.cost')
					  : currentDefects.elementFilter.external
						? user.translate('defects.grouplabel.cost.ag')
						: user.translate('defects.grouplabel.cost.an');
				break;
			case 'smod':
				defects = _.sortBy(defects, function (model) {
					return model.has('smod') ? -model.get('smod') : 0;
				}, this);
				groupLabel = user.translate('defects.grouplabel.smod');
				break;
			case 'pmod':
				defects = _.sortBy(defects, function (model) {
					return model.has('pmod') ? -model.get('pmod') : 0;
				}, this);
				groupLabel = user.translate('defects.grouplabel.pmod');
				break;
			}
			
			var groupOrder = [];
			var processed = {};
			//group them
			switch (group) {
			case '':
				this.groupOrder = null;
				this.defectsGrouped = {};
				this.defectsGrouped[groupLabel] = defects;
				break;
			case 'status':
				this.groupOrder = null;
				var statusText = user.translate('defects.group.status');

				var correctOrder = ["fix","open","discount","agPending","fixed","determined", "terminated", "irrelevant","observed","rejected"];
				var sortedDefects = _.sortBy(defects, function(defect){ return _.indexOf(correctOrder,defect.getStatusId());});
				this.defectsGrouped = _.groupBy(sortedDefects, function (model) { return statusText + ': ' +model.getStatusText()});
				//this.defectsGrouped = _.groupBy(defects, function (model) { return statusText + ': ' + model.getStatusText(); });
				break;
			case 'subcontractor':
				this.groupOrder = null;
				var subcontractorTextShort = user.translate('defects.group.subcontractor.short');
				this.defectsGrouped = _(defects)
					.groupBy(function (model) { return model.get('subcontractor'); })
					.transform(function (result, value, key) {
						var subcontractor = subcontractors.get(key);
						result[subcontractorTextShort + ': ' + (subcontractor ? subcontractor.getLabel() : (key !== 'null' ? key : user.translate('defects.group.nosubcontractor')))] = value;
					})
					.valueOf();
				break;
			case 'location':
				this.groupOrder = null;
				this.defectsGrouped = _.groupBy(defects, function (model) {
					return ((model.get('location') || ['']).slice(0, -1).join(', ') || user.translate('defects.group.nolocation'));
				});
				break;
			case 'deadline':
				groupOrder = [];
				processed = {};
				var noDeadlineText = user.translate('defects.group.nodeadline');
				var noDeadlineDefects = false;
				this.defectsGrouped = _.groupBy(defects, function (model) {
					var deadline = model.get('deadline');
					if (deadline != null) {
						var deadlineDate = moment(deadline);
						var deadlineFormatted = deadlineDate.format('L');
						if (processed[deadlineFormatted] == null) {
							processed[deadlineFormatted] = true;
							groupOrder.push({
								text: deadlineFormatted,
								milliseconds: deadlineDate.toDate().getTime()
							});
						}
						return deadlineFormatted;
					} else {
						noDeadlineDefects = true;
						return noDeadlineText;
					}
				});
				if (noDeadlineDefects) {
					groupOrder.push({ text: noDeadlineText, milliseconds: Number.MAX_VALUE });
				}
				this.groupOrder = groupOrder.length ? _.pluck(_.sortBy(groupOrder, function (group) { return group.milliseconds; }), 'text') : null;
				break;
			case 'smod':
				this.defectsGrouped = _.groupBy(defects, function (model) {
                    var smod = model.get('smod');
                    if (!smod || smod === 0) {
                        return user.translate('defects.group.nosubcontractorchange');
                    } else {
                        var el = moment((946684800 + smod) * 1000);  // 946684800 is 2000-01-01 - start date calculated in java, 1000 - multiplication by ms
                        return el.format('DD.MM.YYYY');
                    }
                }).valueOf();

                this.groupOrder = Object.keys(this.defectsGrouped).sort(function(a,b) {
                	if(a.match(/[a-z]/i) || b.match(/[a-z]/i)) {
                		return -1;
					}
                    var date_a = moment(a, "DD.MM.YYYY");
                    var date_b = moment(b, "DD.MM.YYYY");
                    return date_b - date_a;
                });

				break;
			case 'pmod':
				this.defectsGrouped = _.groupBy(defects, function (model) {
					var pmod = model.get('pmod');
					if (!pmod || pmod === 0) {
						return user.translate('defects.group.noprincipalchange');
					} else {
						var el = moment((946684800 + pmod) * 1000);  // 946684800 is 2000-01-01 - start date calculated in java, 1000 - multiplication by ms
						return el.format('DD.MM.YYYY');
					}
				}).valueOf();
				this.groupOrder = Object.keys(this.defectsGrouped).sort(function(a,b) {
					if(a.match(/[a-z]/i) || b.match(/[a-z]/i)) {
						return -1;
					}
					var date_a = moment(a, "DD.MM.YYYY");
					var date_b = moment(b, "DD.MM.YYYY");
					return date_b - date_a;
				});

				break;
			case 'topics':
				this.defectsGrouped = _.groupBy(defects, function (model) {
					var inspectionIds = model.get('inspections');
					var jourFixeInspection = null;
					_.each(inspectionIds, function (i) {
						if(inspections.get(i) && inspections.get(i).get('topics') === true) {
							var inspection = inspections.get(i);
							if(jourFixeInspection === null) {
								jourFixeInspection = inspection;
							} else if(new Date(inspection.get('date')) >= new Date(jourFixeInspection.get('date'))) {
								jourFixeInspection = inspection;
							}
						}
					})
					if (jourFixeInspection !== null) {
						var topicsList = jourFixeInspection.get('topicslist');
						if(topicsList.length > 0) {
							var topicName = null;
							_.each(topicsList, function (t) {
								var tp = t;
								if (tp.id === model.get('topic')) {
									topicName = tp.index + ' - ' + tp.value;
								} else {
									_.each(t.subTopics, function(st) {
										if (st.id === model.get('topic')) {
											topicName = tp.index + '.' + st.index + ' - ' + st.value;
										}
									})
								}
							})
							if (topicName !== null) {
								return topicName;
							} else {
								return topicName = user.translate('defect.topics.noTopicChosen');
							}
						} else {
							return user.translate('defect.topics.noTopics');
						}
					} else {
						return user.translate('defect.topics.noTopics');
					}
				})
				this.groupOrder = Object.keys(this.defectsGrouped).sort(function (a, b) {
					var noTopic = user.translate('defect.topics.noTopics');
					var noTopicChosen = user.translate('defect.topics.noTopicChosen');
					if (a === noTopic || b === noTopic || a === noTopicChosen || b === noTopicChosen) {
						if (a === noTopic) return 1;
						if (b === noTopic) return -1;
						if (a === noTopicChosen) return 1;
						if (b === noTopicChosen) return -1;
						return -1;
					}
					var indexA = a.split(' - ')[0].split('.');
					var indexB = b.split(' - ')[0].split('.');

					var majorA = indexA[0] ? parseInt(indexA[0]) : 0;
					var majorB = indexB[0] ? parseInt(indexB[0]) : 0;

					if (majorA < majorB) return -1;
					if (majorA > majorB) return 1;

					var minorA = indexA[1] ? parseInt(indexA[1]) : 0;
					var minorB = indexB[1] ? parseInt(indexB[1]) : 0;

					if (minorA < minorB) return -1;
					if (minorA > minorB) return 1;

					var nameA = a.split(' - ')[1] ? a.split(' - ')[1] : '';
					var nameB = b.split(' - ')[1] ? b.split(' - ')[1] : '';

					return nameA.localeCompare(nameB);
				});
				break;
				case 'creationdate':
					groupOrder = [];
					processed = {};
					var noCreationdateText = user.translate('defects.group.nocreationdate');
					var noCreationdateDefects = false;
					this.defectsGrouped = _.groupBy(defects, function (model) {
						var creation = model.get('created');
						if (creation != null) {
							var creationDate = moment(creation);
							var creationFormatted = creationDate.format('L');
							if (processed[creationFormatted] == null) {
								processed[creationFormatted] = true;
								groupOrder.push({
									text: creationFormatted,
									milliseconds: creationDate.toDate().getTime()
								});
							}
							return creationFormatted;
						} else {
							noCreationdateDefects = true;
							return noCreationdateText;
						}
					});
					if (noCreationdateDefects) {
						groupOrder.push({ text: noCreationdateText, milliseconds: Number.MAX_VALUE });
					}
					this.groupOrder = groupOrder.length ? _.pluck(_.sortBy(groupOrder, function (group) { return group.milliseconds; }).reverse(), 'text') : null;
					break;
            }
		}

	}));
});
