define([
    '../lib/backbone-1.1.2'
], function (
  Backbone
) {
    let page = `<div data-role="header" id='defect-page-header' data-position="fixed">
       </div>
       
       <div id="defectContentDiv" data-role="content">
       <div id="defectEditUiLeft" data-role="content">
       <div id="beforeSketcherDiv">
       <div class="location" data-role="controlgroup" data-type="horizontal" data-mini="true">
       <input type="text" placeholder="<%= t('defect.location') %>" data-wrapper-class="controlgroup-textinput ui-btn"/>
       <span class="qrcodetrigger" data-enhance="false"></span>
       </div>
       
       <!-- BEGIN COST -->
       
       <div class="cost-container global" style="display: none">
       <label><input type="checkbox" data-mini="true"/> <%- t('defect.significant') %></label>
       </div>
       
       <div class="cost-container specific" style="display: none">
       <span class="cost-label"><%- t('cost.title.specific') %>:</span>
       <input type="text" data-wrapper-class="ui-btn-inline ui-mini"/>
       </div>
       
       <div class="cost-container separate" style="display: none">
       <span class="cost-label"><%- t('cost.title.specific') %>:</span>
       <div data-role="controlgroup" data-type="horizontal" data-mini="true">
       <!--<input type="text" data-wrapper-class="ui-btn-inline ui-mini" placeholder="AN"/>-->
       <!--<input type="text" data-wrapper-class="ui-btn-inline ui-mini" placeholder="AG"/>-->
       <input type="text" data-wrapper-class="controlgroup-textinput ui-btn ui-mini" class="ag" placeholder="AG" title="Auftraggeber"/>
       <input type="checkbox" data-wrapper-class="sync-cost-checkbox-wrapper ui-btn ui-corner-all ui-icon-recycle ui-btn-icon-notext" class="sync-cost-checkbox"/>
       <input type="text" data-wrapper-class="controlgroup-textinput ui-btn ui-mini" class="an" placeholder="AN" title="Auftragnehmer"/>
       </div>
       </div>
       
       <!-- END COST -->
       
       <div class="description-container">
       <input data-shortcut="focusInput1" data-inputname="defectDescription" class="description" data-info-type="description" type="text" placeholder="<%= t('defect.description') %>" maxlength="2000"/>
       <div id="textareaDescriptionDiv" class="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset" style="display: none">
       <textarea data-shortcut="focusInput1" data-inputname="defectDescription" class="description" data-info-type="description" placeholder="<%= t('defect.description') %>" maxlength="2000"/>
       </div>
       <div class="iconbar"><span class="fileIndicator"></span><span class="comment-symbol" style="display: none"></span><span data-info-type="description" class="multiLineButton"></span></span><span data-info-type="description" class="singleLineButton"></span></div>
       </div>
       <div class="descriptionlengthindicator" style="position: absolute; z-index: -1; visibility: hidden"></div>
       
       <table class="subcontractor-layout ui-mini">
       <tr class="mode-defect-type-not-principal">
       <td>
       <input data-shortcut="focusInput2" data-inputName="defectSubcontractor" class="subcontractors" type="text" data-wrapper-class="controlgroup-textinput ui-btn" placeholder="<%- t('defect.subcontractor') %>"/>
       <button class="ui-btn ui-corner-all subcontractorsButton" style="margin: 0;"><%- t('defect.subcontractor') %></button>
       </td>
       <td class="external-field">
       <label>
       <input type="checkbox" class="external"/> <%- t('defect.external') %>
       </label>
       <input type="text" class="external-id" placeholder="AG-Nummer"/>
       </td>
       </tr>
       <tr class="mode-defect-type-principal" style="position:relative; height: 38px; display: none;">
       <td colspan="2" style="position: absolute; top: 12px; left: 5px">
       <span class="principal-name"></span>
       </td>
       </tr>
       </table>
       <table style="width: 100%" class="ui-mini">
       <tr class="customProperties">
       <td class = "customProp1">
       <label class = "property1">
       <input type="checkbox" class="property1 boolean" />
       </label>
       <input type="text" class="property1 string"/>
       </td>
       <td class = "customProp2">
       <label class = "property2">
       <input type="checkbox" class="property2 boolean"/>
       </label>
       <input type="text" class="property2 string" />
       </td>
       <td class = "customProp3">
       <label class = "property3">
       <input type="checkbox" class="property3 boolean"/>
       </label>
       <input type="text" class="property3 string" />
       </td>
       </tr>
       </table>
       </div>
           <div class="sketcher" data-enhance="false" style="clear: right"></div>
       <!--<div class="deadline-container">
       <div  class="quick-deadline" data-role="controlgroup" data-type="horizontal" data-mini="true">
       <label>
       <input type="radio"  name="deadline" value="0" data-sa="0" data-wrapper-class="deadline-3-wrapper red"/>
       <span class="deadline-text"><%= t('defect.deadline.immediately') %></span>
       </label>
       <label>
       <input type="radio"  name="deadline" value="0" data-sa="1" data-wrapper-class="deadline-3-wrapper yellow"/>
       <span class="deadline-text">3 <%= t('defect.deadline.days') %></span>
       
       </label>
       <label>
       <input type="radio"  name="deadline" value="0" data-sa="1" data-wrapper-class="deadline-3-wrapper green"/>
       <span class="deadline-text">1 <%= t('defect.deadline.week') %></span>
       </label>
       </div>
       </div>-->
       <div id="status-container-container">
        <div class="status-container">
       
        <div class="status an-ag-line" data-role="controlgroup" data-type="horizontal" data-mini="true">
            <label>
                <input type="checkbox" class="an-status-2" disabled="disabled" data-wrapper-class="disabled an-status-2-wrapper"/>
                <%= t('defect.subcontractorpending') %>
            </label>
       
            <label>
                <input type="checkbox" class="ag-status-2" disabled="disabled" data-wrapper-class="disabled ag-status-2-wrapper"/>
                <%= t('defect.principalpending') %>
            </label>
            <div class="ext-links">
                <div class="ext-link ac-bim-link" style="display: none"></div>
                <div class="ext-link ac-digibau-link" style="display: none"></div>
                <div class="ext-link ha-link" style="display: none"></div>
                <div class="ext-link fd2-link" style="display: none"></div>
                <div class="ext-link checklist-link" style="display: none"></div>
                <div class="ext-link plan-link" style="display: none"></div>
            </div>
        </div>
       <div class="status" data-role="controlgroup" data-type="horizontal" data-mini="true">
       <label data-value="fix" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="fix" data-sa="0"/>
       <span class="status-text"><%= t('defect.status.fix') %></span>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-fix"></span>
       </label>
       
       <label data-value="open" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="open" data-sa="0"/>
       <%= t('defect.status.open') %>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-open"></span>
       </label>
       
       <label data-value="irrelevant" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="irrelevant" data-sa="0"/>
       <span class="status-text"><%= t('defect.status.irrelevant') %></span>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-irrelevant"></span>
       </label>
       
       <label data-value="determined" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="determined" data-sa="0"/>
       <span class="status-text"><%= t('defect.status.determined') %></span>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-determined"></span>
       </label>
       
       <label data-value="rejected" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="rejected" data-sa="0"/>
       <span class="status-text"><%= t('defect.status.rejected') %></span>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-rejected"></span>
       </label>
       
       <label data-value="observed" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="observed" data-sa="0"/>
       <span class="status-text"><%= t('defect.status.observed') %></span>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-observed"></span>
       </label>
       
       <label data-value="discount" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="discount" data-sa="0"/>
       <span class="status-text"><%= t('defect.status.discount') %></span>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-discount"></span>
       </label>
       
       <label data-value="accepted" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="fixed" data-sa="1" />
       <span class="status-text"><%= t('defect.status.accepted') %></span>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-accepted"></span>
       </label>
       
       <label data-value="fixed" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="fixed" data-sa="0"/>
       <span class="status-text"><%= t('defect.status.fixed') %></span>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-fixed"></span>
       </label>
       </div>
       <div class="status-terminated" style="display: none" data-role="controlgroup" data-type="horizontal" data-mini="true">
       <label data-value="terminated" class="ui-disabled" style="padding: 6px 20px 6px 0">
       <input type="radio" name="status" value="terminated" data-sa="0"/>
       <span class="status-text"><%= t('defect.status.terminated') %></span>
       <span class="status-icon ui-btn-icon-notext ui-status-icon-postfix ui-icon-status-terminated"></span>
       </label>
       </div>
       </div>
       
       <div class="ui-grid-b an-status" style="display: none">
       <div class="ui-block-a"><%= t('defect.status') %></div>
       <div class="label ui-block-b"></div>
       <div class="ui-block-c ui-grid-a ui-mini">
       <div class="ui-block-a">
       <label for="defect-an-status-checkbox"><%= t('defect.status.done') %></label>
       
       <input type="checkbox" name="defect-an-status-checkbox" id="defect-an-status-checkbox"> </div>
       <div class="ui-block-b">
       <label class="hints ui-btn ui-btn-inline ui-btn-icon-left ui-icon-info ui-corner-all" style="display: none;"><%= t('defect.hints') %></label>
       </div>
       
       </div>
       </div>
       <div class="ui-grid-b ag-status" style="display: none">
       <div class="ui-block-a"><%= t('defect.status') %></div>
       <div class="label ui-block-b"></div>
       <div class="ui-block-c" data-role="controlgroup" data-type="horizontal" data-mini="true">
       <input type="checkbox" name="defect-ag-status-rejected" id="defect-ag-status-rejected" data-val="rejected" data-pf="0"/>
       <label for="defect-ag-status-rejected"><%= t('defect.principalstatus.rejected') %></label>
       <input type="checkbox" name="defect-ag-status-discount" id="defect-ag-status-discount" data-val="discount" data-pf="0"/>
       <label for="defect-ag-status-discount"><%= t('defect.principalstatus.discount') %></label>
       <input type="checkbox" name="defect-ag-status-accepted" id="defect-ag-status-accepted" data-val="accepted" data-pf="0"/>
       <label for="defect-ag-status-accepted"><%= t('defect.principalstatus.accepted') %></label>
       <input type="checkbox" name="defect-ag-status-fixed" id="defect-ag-status-fixed" data-val="accepted" data-pf="1"/>
       <label for="defect-ag-status-fixed"><%= t('defect.principalstatus.fixed') %></label>
       </div>
       </div>
       </div>
       
       <div class="hoermannOverlay" style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; z-index: 9999; display: none; border: 10px solid #DDD">
       <iframe src="" style="width: 100%; height: 100%; border: none"></iframe>
       </div>
       
       <div data-role="popup" class="deadlineDialog" data-dismissible="false" style="max-width:400px; display: none;">
       <div class="ui-content">
       <fieldset class="deadlinecg" data-role="controlgroup" data-type="horizontal">
       <legend><%= t('defect.deadline') %></legend>
       <input type="date" class="deadline" data-wrapper-class="controlgroup-textinput ui-btn"/>
       </fieldset>
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" data-rel="back" data-transition="flow"><%= t('defect.deadline.cancel') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" data-rel="back"><%= t('defect.deadline.apply') %></button>
       </div>
       </div>
       
       <div data-role="popup" class="gw-deliverydate-dialog" data-dismissible="false" style="max-width:400px; display: none;">
       <div class="ui-content" style="padding-top: 1em;">
       <div class="loading"><%= t('defect.deliverydate.loading') %></div>
       <div class="current-value-container"><span><%= t('defect.deliverydate.currentvalue') %></span><span class="current-value"></span></div>
       <fieldset class="gw-deliverydatecg" data-role="controlgroup" data-type="horizontal">
       <legend><%= t('defect.deliverydate') %></legend>
       <input type="date" class="gw-deliverydate" data-wrapper-class="controlgroup-textinput ui-btn"/>
       </fieldset>
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" data-rel="back" data-transition="flow"><%= t('defect.deliverydate.cancel') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" data-rel="back"><%= t('defect.deliverydate.apply') %></button>
       </div>
       </div>
       
       <div data-role="popup" class="fixDateDialog" data-dismissible="false" style="max-width:400px; display: none;">
       <div class="ui-content">
       <fieldset class="deadlinecg" data-role="controlgroup" data-type="horizontal">
       <legend><%= t('defect.fixdate') %></legend>
       <input type="date" class="deadline" data-wrapper-class="controlgroup-textinput ui-btn"/>
       </fieldset>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" data-rel="back"><%= t('defect.fixdate.apply') %></button>
       </div>
       </div>
       
       <div data-role="popup" class="descriptionDialog" data-dismissible="false" style="width: 400px; display: none;">
       <div class="ui-content" style="padding: 1ex 1ex 0">
       <textarea rows="4" style="margin: 0"></textarea>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.comment.apply') %></button>
       </div>
       </div>
       
       <div data-role="popup" class="commentDialog" data-dismissible="false" style="max-width: 95%; overflow-y: scroll;
           max-height: 500px;display: none;">
       <div class="ui-content">
       <div>
       <p style="padding-top: .5em; margin: 0"><%- t('defect.comment.hint') %></p>
       <textarea rows="5" class="hint" ></textarea>
       </div>
       <p class="references"></p>
       <div class="no-subcontractor" style="border-top: 1px solid #888">
       <p style="padding-top: .5em; margin: 0"><%- t('defect.comment.title') %></p>
       <pre class="comment-text" style="font-family: sans-serif; font-size: 12px; margin: .5em 0 0; white-space: pre-wrap"></pre>
       <table style="width: 100%">
       <tr>
       <td class="date" style="white-space: nowrap"></td>
       <td style="width: 100%"><div style="position: relative; width: 100%"><textarea class="comment-input"/></div></td>
       </tr>
       </table>
       </div>
       <div class="no-principal" style="border-top: 1px solid #888">
       <p style="padding-top: .5em; margin: 0"><%- t('defect.subcontractorcomment.title') %></p>
       <pre class="subcontractorcomment-text" style="font-family: sans-serif; font-size: 12px; margin: .5em 0 0; white-space: pre-wrap"></pre>
       <table style="width: 100%">
       <tr>
       <td class="date" style="white-space: nowrap"></td>
       <td style="width: 100%"><div style="position: relative; width: 100%"><textarea class="subcontractorcomment-input"/></div></td>
       </tr>
       </table>
       </div>
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.comment.cancel') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.comment.apply') %></button>
       </div>
       </div>
       
       <div data-role="popup" class="internalCommentDialog" data-dismissible="false" style="max-width: 95%; overflow-y: scroll;
           max-height: 500px;display: none;">
       <div class="ui-content">
       <div>
       <p style="padding-top: .5em; margin: 0"><%= t('defect.comment.internalcomment') %></p>
       <textarea rows="5" class="internalComment" ></textarea>
       </div>
       <p class="references"></p>
       <div class="no-subcontractor" style="border-top: 1px solid #888">
       <p style="padding-top: .5em; margin: 0"><%- t('defect.comment.title') %></p>
       <pre class="comment-text" style="font-family: sans-serif; font-size: 12px; margin: .5em 0 0; white-space: pre-wrap"></pre>
       <table style="width: 100%">
       <tr>
       <td class="date" style="white-space: nowrap"></td>
       <td style="width: 100%"><div style="position: relative; width: 100%"><textarea class="comment-input"/></div></td>
       </tr>
       </table>
       </div>
       <div class="no-principal" style="border-top: 1px solid #888">
       <p style="padding-top: .5em; margin: 0"><%- t('defect.subcontractorcomment.title') %></p>
       <pre class="subcontractorinternalcomment-text" style="font-family: sans-serif; font-size: 12px; margin: .5em 0 0; white-space: pre-wrap"></pre>
       <table style="width: 100%">
       <tr>
       <td class="date" style="white-space: nowrap"></td>
       <td style="width: 100%"><div style="position: relative; width: 100%"><textarea class="subcontractorcomment-input"/></div></td>
       </tr>
       </table>
       </div>
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.comment.cancel') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.comment.apply') %></button>
       </div>
       </div>
       
       <div data-role="popup" class="infoDialog" data-dismissible="false" style="width: 500px; display: none;">
       <div class="ui-content">
       <p class="info-dialog-title" style="padding-top: .5em"></p>
       
       <h4><%- t('defect.infodialog.initialrecord') %></h4>
       <table>
       <thead style="display: none"><tr><th data-cw="2.5"></th><th data-cw="15.5"></th></tr></thead>
       <tbody class="baseData"></tbody>
       </table>
       
       <h4><%- t('defect.infodialog.statushistory') %></h4>
       <table>
       <thead><tr><th data-cw="3.2"><%- t('defect.infodialog.date') %></th><th data-cw="5"><%- t('defect.infodialog.user') %></th><th data-cw="9"><%- t('defect.infodialog.status') %></th></tr></thead>
       <tbody class="statusHistory"></tbody>
       </table>
       
       <h4 class="requestHistoryBox"><%- t('defect.infodialog.requesthistory') %></h4>
       <table class="requestHistoryBox">
       <thead><tr><th data-cw="2.3"><%- t('defect.infodialog.invitation') %></th><th data-cw="3.2"><%- t('defect.infodialog.date') %></th><th data-cw="3.5"><%- t('defect.infodialog.user') %></th><th data-cw="6"><%- t('defect.infodialog.receiver') %></th><th data-cw="3"><%- t('defect.infodialog.deadline') %></th></tr></thead>
       <tbody class="requestHistory"></tbody>
       </table>
       
       <h4><%- t('defect.infodialog.changehistory') %></h4>
       <table>
       <thead><tr><th data-cw="3.2"><%- t('defect.infodialog.date') %></th><th data-cw="5"><%- t('defect.infodialog.user') %></th><th data-cw="9.8"><%- t('defect.infodialog.newcontent') %></th></tr></thead>
       <tbody class="changeHistory"></tbody>
       </table>
       
       <p style="text-align: center"><button style="width: 200px" class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('popup.default.ok') %></button></p>
       </div>
       </div>
       
       <div data-role="popup" class="inspectionsDialog" data-dismissible="false" style="width: 500px; display: none; max-width: 90vw">
       <div class="ui-content ui-mini">
       
       <h2 style="padding-top: 1ex"><%= t('defect.inspections.title') %></h2>
       
       <p class="no-inspection"><%- t('defect.inspections.noinspection') %></p>
       
       <fieldset data-role="controlgroup" class="inspections-list" data-mini="true"></fieldset>
       
       <div style="text-align: right">
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.inspections.cancel') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.inspections.apply') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="groupDialog" data-dismissible="false" style="width: 500px; display: none; max-width: 90vw">
       <div class="ui-content ui-mini">
       
       <h2 style="padding-top: 1ex"><%= t('defect.group.title') %></h2>
       
       <p class="no-inspection"><%- t('defect.group.editableByAdmin') %></p>
       
       <select class="groupSelect">
       </select>
       
       <div style="text-align: right">
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.inspections.cancel') %></button>
       <button class="cancel2 ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('popup.default.ok') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.inspections.apply') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="escalationLevelDialog" data-dismissible="false" style="width: 500px; display: none; max-width: 90vw">
       <div class="ui-content ui-mini">
       
       <h1 style="text-align: center"><%= t('defect.escalationlevel.dialog.header') %></h1>
       
       <p class="no-escalationLevels"><%= t('defect.escalationlevel.dialog.nolevelsset') %></p>
       
       <p class="current-escalationLevel" style="font-weight: bold"></p>
       
       <fieldset data-role="controlgroup" class="escalationLevels-list" data-mini="true">
       <div id="box-escal1" class="escal-box">
       <label for="defect-escal1" class="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ui-radio-off"><%= t('defect.escalationlevel.dialog.firstlevel') %></label>
       <input type="radio" class="defect-escal" name="defect-escal" id="defect-escal1" value="1"/>
       </div>
       <div id="box-escal2" class="escal-box">
       <label for="defect-escal2" class="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ui-radio-off"><%= t('defect.escalationlevel.dialog.secondlevel') %></label>
       <input type="radio" class="defect-escal" name="defect-escal" id="defect-escal2" value="2"/>
       </div>
       <div id="box-escal3" class="escal-box">
       <label for="defect-escal3" class="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ui-radio-off"><%= t('defect.escalationlevel.dialog.thirdlevel') %></label>
       <input type="radio" class="defect-escal" name="defect-escal" id="defect-escal3" value="3"/>
       </div>
       <div id="box-escal4" class="escal-box">
       <label for="defect-escal4" class="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ui-radio-off"><%= t('defect.escalationlevel.dialog.terminationlevel') %></label>
       <input type="radio" class="defect-escal" name="defect-escal" id="defect-escal4" value="4"/>
       </div>
       </fieldset>
       
       <div style="text-align: right">
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.escalationlevel.dialog.cancelbutton') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.escalationlevel.dialog.okbutton') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="defectTypeDialog" data-dismissible="false" style="width: 280px; display: none;">
       <div class="ui-content ui-mini">
       
       <fieldset data-role="controlgroup" style="margin: 0 0 1ex">
       <legend style="margin-top: 1ex"><%= t('defect.type.title') %></legend>
       </fieldset>
       
       <div style="text-align: right">
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.inspections.cancel') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%= t('defect.inspections.apply') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="follow-up-defect-dialog" data-dismissible="false" style="width: 280px; display: none;">
       <div class="ui-content ui-mini">
       
       <div class="form" style="padding: 0.5rem 0"></div>
       
       <div style="text-align: right">
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.followup.cancel') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%= t('defect.followup.create') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="sm-defect-dialog" data-dismissible="false" style="width: 282px; display: none;">
       <div class="ui-content ui-mini">
       
       <div class="form" style="padding: 0.5rem 0"></div>
       
       <div style="text-align: right">
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%= t('defect.sm.cancel') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%= t('defect.sm.create') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="change-subcontractor-dialog" data-dismissible="false" style="width: 280px; display: none;">
       <div class="ui-content ui-mini">
       
       <div class="form" style="padding: 0.5rem 0"></div>
       
       <div style="text-align: right">
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0; padding-left: 0.2rem; padding-right: 0.2rem"><%= t('defect.changesubcontractor.cancel') %></button>
       <button class="inplace ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0; padding-left: 0.2rem; padding-right: 0.2rem"><%= t('defect.changesubcontractor.change') %></button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0; padding-left: 0.2rem; padding-right: 0.2rem"><%= t('defect.changesubcontractor.duplicateandchange') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="repeat-defect-popup" data-dismissible="false" data-theme="a" data-history="false" data-transition="pop">
            <div class="ui-header" data-role="header" data-theme="a">
                <h1 class="ui-title"><%- t('defect.repeatdefect.popup.title') %></h1>
            </div>
            <div class="ui-content">
                <div class="repeat-defect-popup-content">
                    <div class="reapeat-defect-popup-location-search-holder">
                        <fieldset class="repeat-defect-popup-location" data-role="controlgroup" data-type="horizontal" data-mini="true"></fieldset>
                        <input class="repeat-defect-popup-search" type="text" data-type="search" data-wrapper-class="controlgroup-textinput repeat-defect-popup-search-wrapper" data-mini="true" placeholder="<%- t('defect.repeatdefect.popup.search.placeholder') %>"/>
                    </div>
                    <fieldset class="repeat-defect-popup-select-all" data-role="controlgroup" data-corners="false" data-mini="true">
                        <input type="checkbox" id="repeat-select-all"/>
                        <label for="repeat-select-all"><%- t('defect.repeatdefect.popup.selectall') %></label>
                    </fieldset>
                    <div class="repeat-defect-popup-selection-list" data-role="controlgroup" data-corners="false"></div>
                </div>
                <div class="repeat-defect-popup-buttons">
                    <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"><%- t('defect.repeat.cancel') %></button>
                    <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a"></button>
                </div>
            </div>
       </div>
       
       <div data-role="popup" class="gw-notify-dialog" data-dismissible="false" style="width: 300px; display: none;">
       <div class="ui-content ui-mini">
       
       <div style="padding-top: 5px"><%- t('defect.gw.notify') %></div>
       
       <div style="margin-top: 5px"><%- t('defect.gw.owner') %></div>
       
       <div style="position: relative" class="owner"></div>
       
       <div style="text-align: right">
       <button class="send ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.notify.send') %></button>
       <button class="download ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.notify.download') %></button>
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.notify.cancel') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="gw-sendstatus-dialog" data-dismissible="false" style="width: 300px; display: none;">
       <div class="ui-content ui-mini">
       
       <div style="padding-top: 5px"><%- t('defect.gw.sendstatus') %></div>
       
       <div style="margin-top: 5px"><%- t('defect.gw.owner') %></div>
       
       <div style="position: relative" class="owner"></div>
       
       <div style="text-align: right">
       <button class="send ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.sendstatus.send') %></button>
       <button class="download ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.sendstatus.download') %></button>
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.sendstatus.cancel') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="gw-finished-dialog" data-dismissible="false" style="width: 300px; display: none;">
       <div class="ui-content ui-mini">
       
       <div style="padding-top: 5px"><%- t('defect.gw.finished') %></div>
       
       <div style="margin-top: 5px"><%- t('defect.gw.owner') %></div>
       
       <div style="position: relative" class="owner"></div>
       
       <div style="text-align: right">
       <button class="send ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.finished.send') %></button>
       <button class="download ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.finished.download') %></button>
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.finished.cancel') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="gw-reject-dialog" data-dismissible="false" style="width: 300px; display: none;">
       <div class="ui-content ui-mini">
       
       <div style="padding-top: 5px" class="gw-reject-dialog-message"><%- t('defect.gw.reject') %></div>
       
       <div style="margin-top: 5px" class="gw-reject-dialog-select-label"><%- t('defect.gw.owner') %></div>
       
       <div style="position: relative" class="owner"></div>
       
       <div style="text-align: right">
       <button class="send ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.reject.send') %></button>
       <button class="download ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.reject.download') %></button>
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.gw.reject.cancel') %></button>
       </div>
       
       </div>
       </div>
       
       <div data-role="popup" class="subcontractor-rejectobjection-dialog" data-dismissible="false" style="width: 300px; display: none;">
        <div class="ui-content ui-mini">
            <div style="padding-top: 5px"><%- t('defect.sc.rejectobjection') %></div>
<!--            <div style="padding-top: 5px"><%- t('defect.agcomment') %></div>-->
       
            <div style="margin-top: 5px"><%- t('defect.sc.recipient') %></div>
       
            <div style="position: relative" class="subcontractor"></div>
<!--             <table style="width: 100%">-->
<!--              <tr>-->
<!--            <td class="date" style="white-space: nowrap"></td>-->
                 <div style="margin-top: 5px"><%- t('defect.sc.rejectobjection.reason') %></div>
<!--             <td style="width: 100%">-->
             <div style="position: relative; width: 100%"><textarea class="subcontractor-rejectobjection-input"/></div>
<!--             </td>-->   
            <div style="text-align: right">
                
                <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.sc.cancel') %></button>
                 <button class="send ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.rejection.objection.saveAndNext') %></button>
            </div>
        </div>
       </div>
        <div data-role="popup" class="subcontractor-rejectobjection-delivery-options" data-dismissible="false" style="width: 300px; display: none;">
        <div class="ui-content ui-mini">
            <div style="padding-top: 5px"><%- t('defect.sc.rejectobjection') %></div>  
            <div style="text-align: right">
                <button class="send ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.sc.send') %></button>
                <button class="download ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.sc.download') %></button>
                <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a" style="margin-right: 0"><%- t('defect.sc.cancel') %></button>
            </div>
        </div>
       </div>
       
       
       <div data-role="popup" class="showPersonPopup">
       <div style="height: 100%; width: 100%; overflow-y: scroll; margin-left: 12px">
       <h2 style="margin-top: 1em"><%= t('person.gui') %></h2>
       
       <div style="display: flex; flex-direction: column" >
       <div>
       <h3><%= t('person.primaryContact') %></h3>
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="namePerson1"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="addressPerson1"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="phonePerson1"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="mobilePerson1"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="emailPerson1"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="sexPerson1"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="typePerson1"></div>
       </div>
       </div>
       
       <div>
       <h3><%= t('person.secondaryContact') %></h3>
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="namePerson2"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="addressPerson2"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="phonePerson2"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="mobilePerson2"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="emailPerson2"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="sexPerson2"></div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px 20px">
       <div class="typePerson2"></div>
       </div>
       </div>
       </div>
       
       <div class="ui-field-contain" style="margin: 10px">
       <div style="display: inline-block"><%= t('person.expirationText1') %></div>
       <div style="display: inline-block" class="expirationDatePerson"></div>
       <div style="display: inline-block"><%= t('person.expirationText2') %></div>
       
       </div>
       
       <div class="buttons" style="display: flex; flex-direction: row" style="margin: 10px">
       <button class="update" style="margin: 10px;"><%= t('person.edit') %></button>
       <button class="cancel" style="margin: 10px;"><%= t('person.close') %></button>
       </div>
       </div>
       </div>
       
       <div data-role="popup" class="addPersonPopup">
       <div style="height: 100%; width: 100%; overflow-y: scroll; margin: 0 14px;">
       <h2><%= t('person.gui') %></h2>
       
       <div style="display: flex; flex-direction: column">
       <div>
       <h3 style="margin-left: 10px; margin-right: 15px"><%= t('person.primaryContact') %></h3>
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.firstname') %>" class="firstNamePerson1" />
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.surname') %>" class="surnamePerson1" />
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.address') %>" class="addressPerson1"/>
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.phone') %>" class="phonePerson1"/>
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.mobile') %>" class="mobilePerson1"/>
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.email') %>" class="emailPerson1"/>
       </div>
       
       <div style="margin: 10px;">
       <select class="sexPerson1" >
       <option value=""></option>
       <option value="male"><%= t('person.male') %></option>
       <option value="female"><%= t('person.female') %></option>
       <option value="divers"><%= t('person.divers') %></option>
       </select>
       </div>
       
       <div style="margin: 10px">
       <input type="text" placeholder="<%= t('person.type') %>" class="typePerson1" />
       </div>
       </div>
       
       <div>
       <h3 style="margin-left: 10px; margin-right: 15px"><%= t('person.secondaryContact') %></h3>
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.firstname') %>" class="firstNamePerson2" />
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.surname') %>" class="surnamePerson2" />
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.address') %>" class="addressPerson2"/>
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.phone') %>" class="phonePerson2"/>
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.mobile') %>" class="mobilePerson2"/>
       </div>
       
       <div style="margin: 10px;">
       <input type="text" placeholder="<%= t('person.email') %>" class="emailPerson2"/>
       </div>
       
       <div style="margin: 10px;">
       <select class="sexPerson2" >
       <option value=""></option>
       <option value="male"><%= t('person.male') %></option>
       <option value="female"><%= t('person.female') %></option>
       <option value="divers"><%= t('person.divers') %></option>
       </select>
       </div>
       
       <div style="margin: 10px">
       <input type="text" placeholder="<%= t('person.type') %>" class="typePerson2" />
       </div>
       </div>
       </div>
       
       
       <div class="deadlineChooserPerson" style="margin: 10px">
       <div><%= t('person.expirationDate') %></div>
       <input id="deadline-for-selected-defects-picker" type="date" class="deadline" data-wrapper-class="controlgroup-textinput ui-btn"/>
       </div>
       
       <div class="buttons" style="display: flex; flex-direction: row" style="margin: 10px">
       <button class="cancel" style="margin: 10px;"><%= t('person.cancel') %></button>
       <button class="send" style="margin: 10px;"><%= t('person.save') %></button>
       </div>
       </div>
       </div>
       
       <div data-role="popup" class="showWarrantyDataPopup">
       <button class="closeWarrantyDataPopup ui-block-b ui-btn ui-btn-a ui-icon-delete ui-btn-icon-right ui-btn-icon-notext" style="left: 90%; border: none;background-color: #dddd;padding: 5px;">...</button>
       <div class="text" style="background-color: #dddd; padding: 10px;"/>
       <div class="subcontractor" style="margin: 5px;"/>
       <div style="display: flex; flex-direction: row">
       <div class="from" style="margin: 5px;"/>
       <div style="margin: 5px;">-</div>
       <div class="to" style="margin: 5px;"/>
       </div>
       <div class="isPersonalContribution" style="margin: 5px;"/>
       <div class="location" style="margin: 5px;"/>
       <div class="text2" style="margin: 5px; color: red"/>
       <button class="toWarrantyDataOverview ui-btn ui-icon-back" style="width: 90%; left: 5%;"><%= t('defect.warrantyData.toWarrantyOverview') %></button>
       </div>
       
       <div data-role="popup" class="additionalInfoEditPopup" style="min-width: 240px;">
       <div class="ui-content ui-mini">
       <h3 style="text-align: center"></h3>
       <input type="text" infoType="STRING" placeholder="String">
       <input type="number" infoType="NUMBER" placeholder="Number">
       <select type="text" infoType="LIST" />
       <input type="checkbox" infoType="BOOL" style="left: calc(50% - 11px); cursor: pointer">
       <input type="time" infoType="TIME">
       <input type="date" infoType="DATE">
       
       <div class="buttons" style="text-align: center;">
       <button class="cancel ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a">Abbrechen</button>
       <button class="ok ui-btn ui-corner-all ui-shadow ui-btn-inline ui-btn-a">Ok</button>
       </div>
       </div>
       </div>
       
       </div>
       <div id="defectEditUiRight" data-role="popup" class="actionsmenu">
       <ul data-role="listview" data-inset="true" style="/*min-width: 250px*/">
       
       <!--Menu Layout Pin-->
       <li data-icon="false" id="pinLi" style="padding: 0; border: 0">
       <!--<label for="actionsmenuLayoutPin" id="actionsmenuLayoutPinLabel" style="border: 0; padding-top: 1px; padding-bottom: 1px;">-->
       <!--<input name="actionsmenuLayoutPin" id="actionsmenuLayoutPin" type="checkbox"/>-->
       <!--<%- t('defect.menu.layoutPin') %>-->
       <!--</label>-->
       <a id="actionsmenuLayoutPin" class="ui-btn ui-corner-all ui-icon-gear ui-btn-icon-notext"></a>
       </li>
       
       <!--Menu entries of the "info" headline-->
       <li data-icon="false" data-headline="Info" data-visiblegroup="true" disabled class="actionsMenuHeadline actionsMenuInfo ui-btn ui-icon-carat-d ui-btn-icon-right"><%- t('defect.infodialog.title') %></li>
       
       <li data-icon="false" class="actionsMenuItem actionsMenuInfo number"><a class="number ui-disabled" href="javascript:void(0)" style="border-top: none"> <span class="current-number-name"><%- t('defect.menu.number.new') %></span><span class="current-number actionsMenuItemValue"></span><span class="current-number-after"> -</span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuInfo"><a class="type" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.type') %> <span class="current-type actionsMenuItemValue"></span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuInfo"><a class="setDeadline" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.deadline') %> <span class="current-deadline actionsMenuItemValue"></span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuInfo setAgDeadlineLi"><a class="setAgDeadline" href="javascript:void(0)" style="border-top: none"><span class="agdeadlineName"><%- t('defect.menu.agdeadline') %> </span><span class="current-agdeadline actionsMenuItemValue"></span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuInfo"><a class="change-subcontractor" href="javascript:void(0)" style="border-top: none"></span>Auftragnehmer: <span class="current-sub actionsMenuItemValue">-</span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuInfo"><a class="setEscalationLevel actionsMenuInfo" href="javascript:void(0)" style="border-top: none"><%= t('defect.escalationlevel.defectmenu.setescalationlevel') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuInfo"><a class="inspections" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.inspections') %> <span class="current-inspections"></span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuInfo"><a class="group" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.group') %> <span class="current-group"></span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuInfo"><a class="topics" style="border-top: none"><span><%- t('defect.menu.topics') %></span><select class="topicselection"></select></a></li>
       
       <!--Menu entries of the "save" headline-->
       <li data-icon="false" data-headline="Save" data-visiblegroup="true" disabled class="actionsMenuHeadline actionsMenuSave ui-btn ui-icon-carat-d ui-btn-icon-right"><%- t('defect.menu.saveheadline') %></li>
       
       <li data-icon="false" class="actionsMenuItem actionsMenuSave"><a class="save" href="javascript:void(0)" style="border-top: none"><%- t('defect.save') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuSave"><a class="saveandnew" href="javascript:void(0)" style="border-top: none"><%- t('defect.saveandnew') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuSave"><a class="saveandduplicate" href="javascript:void(0)" style="border-top: none"><%- t('defect.saveandduplicate') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuSave"><a class="saveandNext" href="javascript:void(0)" style="border-top: none"><%- t('defect.saveandNext') %></a></li>
       
       <!--Menu entries of the "warranty" headline-->
       <li data-icon="false" data-headline="Warranty" data-visiblegroup="true" disabled class="actionsMenuHeadline actionsMenuWarranty ui-btn ui-icon-carat-d ui-btn-icon-right"><%- t('defect.type.warranty') %></li>
       <!--This is added later on!-->
       <li data-icon="false" class="actionsMenuItem actionsMenuWarranty"><a class="gw-deliverydate" href="javascript:void(0)"><%- t('defect.menu.gw.deliverydate') %>: <span class="current-gwDeliveryDate actionsMenuItemValue">-</span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuWarranty"><a class="gw-notify" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.gw.notify') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuWarranty"><a class="gw-sendstatus" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.gw.sendstatus') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuWarranty"><a class="gw-finished" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.gw.finished') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuWarranty"><a class="showPersonsPopup" href="javascript:void(0)" style="border-top: none"><%- t('defect.persons') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuWarranty"><a class="showWarrantyData" href="javascript:void(0)" style="border-top: none"><%- t('defect.warrantyData') %></a></li>
       
       <!--Menu entries of the "actions" headline-->
       <li data-icon="false" data-headline="Actions" data-visiblegroup="false" disabled class="actionsMenuHeadline actionsMenuActions ui-btn ui-icon-carat-d ui-btn-icon-right"><%- t('defect.menu.actionsheadline') %></li>
       
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="subcontractor-sendrequest" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.sendrequest') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="subcontractor-rejectobjection actionsMenuActions" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.rejectobjection') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="pdf actionsMenuActions" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.pdf') %></a></li>
       
       
       
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="duplicate actionsMenuActions" href="javascript:void(0)"><%- t('defect.duplicate') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="repeatDefect actionsMenuActions" href="javascript:void(0)" style="border-top: none"><%- t('defect.repeat') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="createFollowup actionsMenuActions" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.createfollowup') %></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="createSM actionsMenuActions" href="javascript:void(0)" style="border-top: none"><%- t('defect.menu.createsm') %></a></li>
       
       
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="files actionsMenuActions" href="javascript:void(0)"><%- t('defect.menu.files') %> <span class="hint"><%- t('defect.menu.files.aftersave') %></span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="comment actionsMenuActions" href="javascript:void(0)" style="border-top: none;"><%- t('defect.menu.comment') %><span class="current-notice"></span></a></li>
       <li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="internal_comment actionsMenuActions" href="javascript:void(0)" style="border-top: none;"><%- t('defect.comment.internalcomment') %><span class="current-internal-comment"></span></a></li>
       <!--<li data-icon="false" class="actionsMenuItem actionsMenuActions"><a class="info actionsMenuActions" href="javascript:void(0)" style="border-top: none;"><%- t('defect.menu.info') %></a></li>-->
       
       <!--<li data-icon="false"><a class="delete" href="javascript:void(0)"><%- t('defect.remove') %></a></li> 2015-02-18: Löschen Button soll komplett entfernt werden -->
       <!--<li data-icon="false"><a class="assign" href="javascript:void(0)"><%- t('defect.menu.assign') %></a></li>-->
       <!--<li data-icon="false"><a class="unassign" href="javascript:void(0)"><%- t('defect.menu.unassign') %></a></li>-->
       
       <li data-icon="false" data-headline="AdditionalInfo" data-visiblegroup="false" disabled class="actionsMenuHeadline actionsMenuAdditionalInfo ui-btn ui-icon-carat-d ui-btn-icon-right"><%- t('defect.menu.additionalinfoheadline') %></li>
       
       <!--<li data-icon="false" class="actionsMenuItem actionsMenuAdditionalInfo"><a class="" href="javascript:void(0)" style="border-top: none">Test123</a></li>-->
       
       
       <li data-icon="false" data-headline="Events" data-visiblegroup="true" id="actionsMenuEventsHeadline" disabled class="actionsMenuHeadline actionsMenuEvents ui-btn ui-icon-carat-d ui-btn-icon-right">
       <%- t('defect.menu.events.headline') %>
       <span data-tabdata="eventsTabAll" class="actionsMenuEventsTab selected"><%- t('defect.menu.events.headline.all') %></span>
       <span data-tabdata="eventsTabAn" class="actionsMenuEventsTab"><%- t('defect.log.comment.AN.short') %></span>
       <span data-tabdata="eventsTabAg" class="actionsMenuEventsTab"><%- t('defect.log.comment.AG.short') %></span>
       </li>
       
       <li data-icon="false" class="actionsMenuEventsTabData actionsMenuItem actionsMenuEvents" id="eventsTabAll">
       <table style="width: 100%">
       <tbody>
       <tr>
       <td>
       <input id="commentButtonAn" type="button" value="<%- t('defect.log.comment.ANComment') %>">
       </td>
       <td>
       <input id="commentButtonAg" type="button" value="<%- t('defect.log.comment.AGComment') %>">
       </td>
       </tr>
       </tbody>
       </table>
       <div class="actionsMenuEventsTabInfo"></div>
       </li>
       
       <li data-icon="false" class="actionsMenuEventsTabData actionsMenuItem actionsMenuEvents" style="display:none;" id="eventsTabAn">
       <table class="commentTable1" style="width: 100%">
       <colgroup width="90%"></colgroup>
       <colgroup width="10%"></colgroup>
       <tbody>
       <tr>
       <td class="ancomment-container">
       <input type="text" data-info-type="ancomment" placeholder="<%- t('defect.log.comment.ANComment') %>" class="actionsMenuEventsTabComments ancomment">
       <div id="textareaAnCommentDiv" class="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset" style="display: none">
       <textarea class="ancomment" data-info-type="ancomment" placeholder="<%= t('defect.log.comment.ANComment') %>" maxlength="2000"/>
       </div>
       <div class="iconbar"><span data-info-type="ancomment" class="multiLineButton"></span></span><span data-info-type="ancomment" class="singleLineButton"></span></div>
       </td>
       <td>
       <input class="commentSendButton" type="button" value="Enter">
       </td>
       </tr>
       </tbody>
       </table>
       <table class="commentTable2" style="width: 100%">
       <colgroup width="100%" />
       <tr>
       <td>
       
       <input class="commentButton" type="button" value="<%- t('defect.log.comment.ANComment') %>">
       </td>
       </tr>
       </table>
       <div class="actionsMenuEventsTabInfo"></div>
       </li>
       
       <li data-icon="false" class="actionsMenuEventsTabData actionsMenuItem actionsMenuEvents" style="display:none;" id="eventsTabAg">
       <table class="commentTable1" style="width: 100%">
       <colgroup width="90%"></colgroup>
       <colgroup width="10%"></colgroup>
       <tbody>
       <tr>
       <td class="agcomment-container">
       <input type="text" data-info-type="agcomment" placeholder="<%- t('defect.log.comment.AGComment') %>" class="actionsMenuEventsTabComments agcomment">
       <div id="textareaAgCommentDiv" class="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset" style="display: none">
       <textarea class="agcomment" data-info-type="agcomment" placeholder="<%= t('defect.log.comment.AGComment') %>" maxlength="2000"/>
       </div>
       <div class="iconbar"><span data-info-type="agcomment" class="multiLineButton"></span></span><span data-info-type="agcomment" class="singleLineButton"></span></div>
       </td>
       <td>
       <input class="commentSendButton" type="button" value="Enter">
       </td>
       </tr>
       </tbody>
       </table>
       <table class="commentTable2" style="width: 100%">
       <colgroup width="100%" />
       <tr>
       <td>
       <input class="commentButton" type="button" value="<%- t('defect.log.comment.AGComment') %>">
       </td>
       </tr>
       </table>
       <div class="actionsMenuEventsTabInfo"></div>
       </li>
       </ul>
       </div>
       <div class="dragndropdiv">
       <h1 style="text-align: center"><%- t('defect.dragdrop.headline') %></h1>
       </div>
       </div>
       
       <div class="uploadFileTracker" data-role="popup" data-dismissible="false" data-overlay-theme="a">
       <h2 class="trackerHeadline"><%- t('defect.dragdrop.tracker.headline') %></h2>
       <div class="trackerText"></div>
       <div class="trackerMb"></div>
       <div class="trackerSpeed"></div>
       <button class="finishButton" style="display: none">Ok</button>
       </div>
       
       <div id='defect-page-footer' data-role="footer" data-position="fixed">
       </div>`;
    return page;
})